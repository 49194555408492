h1,
.h1 {
  font-size: rem(61.03);
  font-weight: 700;

  @include breakpoint-down(tablet) {
    font-size: rem(39.06);
  }
}

h2,
.h2 {
  font-size: rem(48.83);
  font-weight: 700;
  line-height: 1.399959;

  @include breakpoint-down(tablet) {
    font-size: rem(31.25);
  }
}

h3,
.h3 {
  font-size: rem(39.06);
  font-weight: 700;
  line-height: 1.399959;

  @include breakpoint-down(tablet) {
    font-size: rem(25);
  }
}

h4,
.h4 {
  font-size: rem(31.25);
  font-weight: 700;

  @include breakpoint-down(tablet) {
    font-size: rem(20);
  }
}

h5,
.h5 {
  font-size: rem(25);
  font-weight: 700;
  line-height: 1.5;

  @include breakpoint-down(tablet) {
    font-size: rem(20);
  }
}

h6,
.h6 {
  font-size: rem(20);
  font-weight: 400;
  line-height: 1.5;

  @include breakpoint-down(tablet) {
    font-size: rem(16);
  }
}

p {
  line-height: 1.6;
}
