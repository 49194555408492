.header {
  @include breakpoint-down(mobile) {
    height: 56px;
  }

  &--notice {
    @include breakpoint-down(mobile) {
      height: 80px;
    }
  }
  .header__inner {
    background-color: #fff;
    @include breakpoint-down(mobile) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 3;
    }

    &--dark {
      background-color: $color-black-07;
    }
  }

  .logo {
    margin-right: 40px;

    @include breakpoint-down(small-desktop) {
      margin-right: 20px;
    }

    @include breakpoint-down(mobile) {
      width: 98px;
      height: 24px;
    }

    a {
      display: block;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    svg {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .btn {
    min-width: 140px;
  }

  .header__flex {
    display: flex;
    align-items: center;
    padding: 24px 0;

    @include breakpoint-down(tablet) {
      justify-content: space-between;
      padding: 9px 0;
    }
  }
}
