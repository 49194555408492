.search-page-holder {
  margin: 50px 0 0;
}

.result-wrapper__item {
  & ~ .result-wrapper__item {
    margin-top: 45px;
  }
}

.result-list {

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    padding-bottom: 5px;
    margin-bottom: 20px;
    color: #000;
  }

  h5 {
    margin-bottom: 10px;
    color: #000;
  }

  ul {
    border-left: 1px solid #000;
    padding-left: 20px;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
    }
  }

  a {
    font-size: 16px;
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}
