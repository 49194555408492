.client-page {
  .text-block {
    padding: 100px 0;
    margin: 0;

    @include breakpoint-down(mobile) {
      padding: 48px 0 35px;
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;

    .header__inner {
      background: none;
      color: #fff;
    }
  }

  .header__inner {
    .nav a:not(.btn) {
      color: #fff;

      &:after {
        background-color: #fff;
      }
    }

    .logo svg path {
      fill: #fff;
    }
  }

  .header__inner:not(.header__inner--dark) .mobile-menu__toggle {
    .line {
      stroke: #fff;
    }

    &.is-active {
      .line {
        stroke: $color-black-07;
      }
    }
  }

  .mobile-menu__modal {
    .nav a:not(.btn) {
      color: $color-black-07;

      &:after {
        background-color: $color-black-07;
      }
    }
  }
}
