.articles {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px -40px;
    list-style: none;

    @include breakpoint-down(tablet) {
      margin: 0 -15px -30px;
    }

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  li {
    width: 33.3333%;
    padding: 0 20px 40px;

    @include breakpoint-down(tablet) {
      padding: 0 15px 30px;
    }

    @include breakpoint-down(mobile) {
      width: 100%;
    }
  }
}
