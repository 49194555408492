.features {
  h4 {
    margin-bottom: 48px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 25px;
    }
  }
}

.feature-boxes {
  .feature-box {
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 1px solid $color-grey-e0;

    @include breakpoint-down(mobile) {
      padding-bottom: 24px;
      margin-bottom: 30px;
    }

    &.is-last {
      border-bottom: 0;
    }

    h6 {
      margin-bottom: 16px;
      color: $color-black-07;
      font-weight: 700;

      @include breakpoint-down(mobile) {
        margin-bottom: 10px;
      }
    }

    ul {
      display: grid;
      padding-top: 6px;
      gap: 24px;
      grid-template-columns: 1fr 1fr 1fr;
      list-style: none;

      @include breakpoint-down(mobile) {
        display: block;
      }
    }

    li {
      padding: 24px;
      border: 1px solid $color-grey-e0;

      @include breakpoint-down(mobile) {
        padding: 16px;
        margin-bottom: 15px;
      }
    }
  }

  .box-title {
    margin-bottom: 6px;
    font-size: rem(16);
    color: $color-black-07;
    font-weight: 500;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
    }
  }

  .box-content {
    font-size: rem(12.8);
    line-height: 1.8;

    @include breakpoint-down(mobile) {
      font-size: rem(10.2);
    }
  }
}

.feature-box__desc {
  max-width: 544px;
  margin-bottom: 16px;

  p {
    @include breakpoint-down(mobile) {
      display: none;
    }
  }
}
