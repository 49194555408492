.projects-list {
  margin-bottom: 123px;

  @include breakpoint-down(mobile) {
    margin-bottom: 25px;
  }

  .projects-list__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 64px;

    @include breakpoint-down(tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-down(mobile) {
      padding: 0 0 48px;
      grid-template-columns: 1fr;
      gap: 48px;
    }
  }
}
