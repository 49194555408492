.text-boxes {
  @include breakpoint-down(mobile) {
    margin-top: 48px;
  }

  & + .text-boxes {
    margin-top: 48px;
  }

  h6 {
    margin-bottom: 24px;
    font-weight: 700;
    color: #000;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @include breakpoint-down(mobile) {
      grid-template-columns: 1fr;
    }
  }

  li {
    list-style: none;
    border: 1px solid $color-grey-e0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    text-decoration: none;
    color: #000;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 0 3px 1px $color-grey-e0;
    }
  }
}
