.grid-boxes {
  margin-bottom: 160px;

  @include breakpoint-down(tablet) {
    margin-bottom: 75px;
  }

  .container {
    @include breakpoint-down(mobile) {
      padding: 0;
      margin: 0;
    }
  }
}

.grid-boxes__inner {
  display: flex;
  gap: 40px;

  @include breakpoint-down(tablet) {
    gap: 20px;
  }

  @include breakpoint-down(mobile) {
    overflow: auto;
    padding: 0 24px;
  }

  .grid-box {
    border: 1px solid #f0f1fa;

    @include breakpoint-down(mobile) {
      flex: 0 0 280px;
    }
  }

  .grid-box__content {
    padding: 24px;

    @include breakpoint-down(mobile) {
      padding: 16px;
    }
  }

  .grid-box__title {
    margin-bottom: 12px;
    font-size: rem(16);
    font-weight: 500;
    color: $color-black-07;
  }

  p {
    font-size: rem(12.8);
    line-height: 1.8;
  }
}
