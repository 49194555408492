.project-item {
  .project-item__inner {
    position: relative;
    display: block;
    padding-bottom: 145.5%;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.5;
      z-index: 1;
    }

    &:hover {
      .project-item__text {
        p {
          max-height: 60px;
          opacity: 1;
        }
      }
    }
  }

  .project-item__text {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    padding: 0 32px;
    z-index: 2;

    @include breakpoint-down(mobile) {
      padding: 0 24px;
      bottom: 24px;
    }

    h6 {
      margin-bottom: 16px;
      font-weight: 700;

      @include breakpoint-down(tablet) {
        margin-bottom: 12px;
      }

      @include breakpoint-down(mobile) {
        margin-bottom: 8px;
        font-size: rem(20);
      }
    }

    p {
      max-height: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      font-weight: 500;

      @include breakpoint-down(mobile) {
        max-height: none;
        opacity: 1;
      }
    }
  }

  .project-item__badge {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 24px;
    top: 0;
    right: 0;
    z-index: 1;
    gap: 8px;
    font-size: 0;

    @include breakpoint-down(mobile) {
      padding: 16px;
    }

    .project-item__badge-inner {
      text-align: right;
    }

    span {
      display: inline-block;
      padding: 6px 12px;
      border: 1px solid $color-blue-30;
      background-color: $color-blue-30;
      font-size: rem(10.24);
      color: #fff;

      @include breakpoint-down(mobile) {
        font-size: rem(10.24);
      }
    }
  }
}
