.btn {
  display: inline-block;
  min-width: 160px;
  padding: 12px 15px;
  border: 1px solid $color-blue-30;
  transition: all 0.3s;
  color: $color-blue-30;
  text-decoration: none;
  text-align: center;
  font-size: rem(16);
  font-weight: 500;

  @include breakpoint-down(mobile) {
    padding: 12px 5px;
    min-width: 152px;
    font-size: rem(12);
  }

  &--fill {
    color: #fff;
    background-color: $color-blue-30;

    &:hover {
      background-color: #fff;
      color: $color-blue-30;
    }
  }

  &--fullwidth {
    width: 100%;
  }

  &--white {
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $color-blue-30;
    }
  }

  &--white--fill {
    border-color: #fff;
    color: #000;
    background-color: #fff;

    &:hover {
      color: #000;
      background-color: #f8f8f8;
    }
  }

  &--black--fill {
    border-color: #000;
    color: #fff;
    background-color: #000;

    &:hover {
      color: #fff;
      background-color: #201e37;
    }
  }

  &--grey {
    border-color: $color-base-5e;
    color: $color-base-5e;

    &:hover {
      background: $color-blue-30;
      color: #fff;
      border-color: $color-blue-30;
    }
  }

  &--rounded {
    border-radius: 16rem;
  }
}
