.divider {
  height: 1px;
  margin: 66px 0 96px;
  background-color: $color-grey-e0;

  @include breakpoint-down(tablet) {
    margin: 15px 0 45px;
  }

  @include breakpoint-down(mobile) {
    margin: 40px 0;
  }
}
