.store-images {
  margin-bottom: 160px;

  @include breakpoint-down(tablet) {
    margin-bottom: 75px;
  }

  @include breakpoint-down(mobile) {
    padding: 30px 0 0;
    margin-bottom: 62px;
  }

  h4 {
    margin-bottom: 32px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 15px;
      font-size: rem(25);
    }
  }
}

.store-images__inner {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @include breakpoint-down(mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .store-image {
    display: block;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  }
}
