.section-testimonial {
  padding: 100px 0;
  background: $color-black-07;
}

.testimonial {
  display: flex;
  width: 100%;
  justify-content: center;
  color: $color-white;
  text-align: center;

  .testimonial__content {
    max-width: 998px;
    margin-bottom: 44px;
    font-size: rem(48.83);
    font-weight: 700;
    line-height: 1.4;

    @include breakpoint-down(mobile) {
      margin-bottom: 36px;
      font-size: rem(25);
    }
  }

  .testimonial__content p {
    line-height: inherit;
  }

  .testimonial__content--small {
    max-width: 730px;
    font-size: rem(20);
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }
  }

  .testimonial__content--medium {
    max-width: 830px;
    font-size: rem(32);
    font-weight: 700;
    line-height: 1.399959;

    @include breakpoint-down(mobile) {
      font-size: rem(25);
    }
  }

  .testimonial__meta {
    display: flex;
    justify-content: center;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  .testimonial__meta-position {
    position: relative;
    margin-left: 130px;

    @include breakpoint-down(mobile) {
      margin: 10px 0 0;
    }
  }

  .testimonial__meta-position:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 98px;
    height: 1px;
    margin-right: 16px;
    background: $color-white;

    @include breakpoint-down(mobile) {
      display: none;
    }
  }
}
