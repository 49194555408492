$slideTextHeadFontSizeLg: rem(61.03);
$slideTextHeadFontSizeSm: rem(39.06);
$slideTextHeadLeadingSm: 1.199949;

.intro-slide-text {
  padding: 156px 0 137px;

  @include breakpoint-down(mobile) {
    padding: 68px 0 80px;
  }

  .logos-row {
    margin-left: -8px;
    margin-top: 39px;
    gap: 16px;

    @include breakpoint-down(mobile) {
      padding-right: 57px;
      margin: 32px 0 0;
    }
  }
}

@media screen and (max-width: 420px) {
  .intro-slide-text {
    height: calc(
      $slideTextHeadFontSizeSm * $slideTextHeadLeadingSm * 6 + 1.5625rem + 39px +
        68px + 80px
    );
    overflow: hidden;
  }
}

.intro-slide-text__slider {
  display: block;
  height: 80px;
  overflow: hidden;

  @include breakpoint-down(mobile) {
    height: 106px;
  }

  span {
    display: block;
  }

  .embla__container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .embla__slide {
    position: relative;
    flex: 0 0 100%;
    height: 100%;
    color: $color-blue-30;

    @include breakpoint-down(mobile) {
      display: flex;
      flex: 0 0 106px;
      height: 106px;
      padding: 5px 0;
      align-items: center;
    }
  }
}

.intro-slide-text__head {
  display: flex;
  flex-wrap: wrap;
  font-size: rem(61.03);
  line-height: 1.400131;
  font-weight: 700;

  @include breakpoint-down(mobile) {
    font-size: rem(39.06);
    line-height: $slideTextHeadLeadingSm;
  }

  span {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .intro-slide-text__title {
    width: 100%;
  }
}

.intro-slide-text-transition {
  display: "inline-flex";
  whitespace: "normal";
  margin: 0.5rem 0;
  color: $color-blue-30;
}

.intro-slide-text-transition-auto {
  & > div {
    height: auto !important;
  }
}

@media screen and (max-width: 420px) {
  .intro-slide-text-transition-block {
    & > div {
      height: calc(
        $slideTextHeadFontSizeSm * $slideTextHeadLeadingSm * 2
      ) !important;
    }
  }
}

@media screen and (min-width: 420px) {
  .intro-slide-text-transition {
    height: auto !important;
    margin: 0;
  }
}
