.main {
  padding-bottom: 96px;

  @include breakpoint-down(tablet) {
    padding-bottom: 75px;
  }

  @include breakpoint-down(mobile) {
    padding-bottom: 96px;
  }

  &--dark {
    background-color: $color-black-07;
    color: #fff;
  }
}
