.icon-cards--extra {
  position: relative;
  h3 {
    text-align: center;
    font-size: 5.5rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -3.52px;
    @include breakpoint-down(mobile) {
      font-size: 2.5rem;
      letter-spacing: -1.564px;
    }
  }

  .icon-cards__grid {
    max-width: 860px;
    margin-inline: auto;
    padding-block: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    @include breakpoint-down(mobile) {
      padding-block: 2rem;
    }
    .icon-cards--grid-row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      max-height: 80px;
    }
    .icon-card.icon-card--hover {
      border-color: #2c1bb8;
      gap: 1rem;
      cursor: pointer;

      .icon-card__body-text {
        height: fit-content;
        max-width: 20rem;
        opacity: 1;
        transition: height 0.5s ease 0.25s, opacity 0.25s ease 0.125s;
      }
      @include breakpoint-up(mobile) {
        .icon-card__icon {
          display: none;
        }
      }
      .icon-card__head {
        pointer-events: none;
      }
    }
    &.icon-cards__grid--desktop {
      @include breakpoint-down(mobile) {
        display: none;
      }
    }
    &.icon-cards__grid--mobile {
      @include breakpoint-up(mobile) {
        display: none;
      }
    }
  }
  @include breakpoint-up(mobile) {
    .icon-cards__grid:has(.icon-card--active)
      .icon-card--extra:not(.icon-card--active) {
      opacity: 0.25;
      transition: opacity 0.25s ease;
      pointer-events: none;
    }
  }

  .icon-card--extra {
    width: fit-content;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    justify-content: center;
    gap: 0;
    transition: gap 0.25s ease;
    @include breakpoint-down(mobile) {
      width: 100%;
      text-align: start;
    }
    &:hover {
      cursor: pointer;
    }
    .icon-card__head {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding: 1.25rem;
      @include breakpoint-down(mobile) {
        justify-content: flex-start;
      }
      @include breakpoint-up(mobile) {
        width: fit-content;
      }
      .icon-card__icon {
        padding: 0.5rem;
        border-radius: 0.5rem;
        background: #2c1bb8;
      }

      .icon-card__text {
        font-size: 25px;
        font-weight: 600;
        line-height: 120%; /* 30px */
        letter-spacing: -1px;
        @include breakpoint-down(mobile) {
          font-size: 14px;
          font-weight: 600;
          line-height: 100%; /* 14px */
          letter-spacing: -0.28px;
        }
      }
      .icon-card__plus {
        width: 2.25rem;
        height: 2.25rem;
        aspect-ratio: 1 / 1;
        border: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        img {
          filter: brightness(3);
        }
        @include breakpoint-up(mobile) {
          display: none;
        }
      }
    }

    .icon-card__body-text {
      color: rgba(255, 255, 255, 0.5);
      padding-top: 0rem;
      height: 0rem;
      overflow: hidden;
      transition: height 0.25s ease, padding-top 0.25s ease;
    }
    &.icon-card--active {
      @include breakpoint-up(mobile) {
        background: #1f1e37;
        z-index: 3;
      }
      .icon-card__head {
        @include breakpoint-down(mobile) {
          padding-bottom: 0;
        }
      }
      .icon-card__body-text {
        padding: 1.25rem;
        height: auto;
        @include breakpoint-up(mobile) {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
    // .icon-card__body-text {
    //   height: 0;
    //   opacity: 0;
    //   padding-top: 0;
    //   max-width: 0;
    //   border-top: none;
    //   pointer-events: none;
    //   @include breakpoint-down(mobile) {
    //     font-size: 14px;
    //   }
    // }
  }
  .icon-card__plus {
    padding: 0.5rem;
    width: 5.125rem;
    height: 5.125rem;
    aspect-ratio: 1 / 1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  .btn--white {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-inline: auto;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 160% !important;

    @include breakpoint-up(mobile) {
      width: fit-content !important;
    }

    @include breakpoint-down(mobile) {
      justify-content: space-between;
      margin-top: 2rem;
    }
  }

  @include breakpoint-up(mobile) {
    .container {
      position: relative;
      z-index: 2;
    }
    .icon-cards--extra__circle-wrap {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .icon-cards--extra__circle {
        position: absolute;
        inset: 50%;
        transform: translate(-50%, -50%);
        width: 0%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        animation-name: popCircleClose;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-fill-mode: backwards;
      }
      &:hover {
        .icon-cards--extra__circle {
          animation-name: popCircleOpen;
          animation-fill-mode: forwards;
        }
      }
    }
    &:hover:has(.icon-card--active)
      .icon-cards--extra__circle-wrap
      .icon-cards--extra__circle {
      animation-name: popCircleOpen;
      animation-fill-mode: forwards;
    }
  }
  @keyframes popCircleOpen {
    from {
      width: 0%;
    }
    to {
      width: 80%;
    }
  }

  @keyframes popCircleClose {
    from {
      width: 80%;
    }
    to {
      width: 0%;
    }
  }
}
