.list-tags {
  @include breakpoint-down(tablet) {
    margin-top: 26px;
  }

  @include breakpoint-down(mobile) {
    overflow: auto;
    width: 100vw;
    padding-bottom: 10px;
    margin-left: -24px;
  }

  ul {
    display: flex;
    margin: 0 -6px;
    list-style: none;

    @include breakpoint-down(mobile) {
      padding: 0 18px;
      margin: 0;
    }
  }

  li {
    padding: 0 6px;
  }

  a {
    display: block;
    padding: 4px 12px;
    border-radius: 50px;
    background: rgba(255,255,255,.3);
    font-size: rem(12.8);
    line-height: 1.8;
    color: $color-white;
    text-decoration: none;
    transition: background .3s, color .3s;

    &:hover {
      background: $color-white;
      color: $color-blue-30;
    }
  }

  .current a {
    background: $color-blue-30;
    color: $color-white;
  }
}
