.theme-top {
  position: relative;
  margin-bottom: 160px;
  padding-top: 60px;

  @include breakpoint-down(tablet) {
    margin-bottom: 64px;
  }

  @include breakpoint-down(mobile) {
    padding-top: 40px;
  }

  &:after {
    @include breakpoint-down(tablet) {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 200vw;
      height: calc(100% - 100px);
      background-color: $color-light-f0;
      transform: translateX(-50%);
    }

    @include breakpoint-down(mobile) {
      height: calc(100% - 80px);
    }
  }
}

.theme-top__inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint-down(tablet) {
    display: block;
  }
}

.theme-top__slider {
  position: relative;
  z-index: 2;
  flex: 0 0 57.0783%;
  max-width: 57.0783%;

  @include breakpoint-down(tablet) {
    max-width: 100%;
  }
}

.slider-holder {
  @include breakpoint-down(tablet) {
    margin-bottom: 32px;
  }
  .slick-slider {
    padding-left: 86px;

    @include breakpoint-down(tablet) {
      padding-left: 0;
    }
  }

  .slick-list {
    @include breakpoint-down(tablet) {
      margin-bottom: 16px;
    }
  }

  .slick-slide {
    padding: 0 10px 10px;
    & > * {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
  }

  .slick-dots {
    position: absolute;
    top: 0;
    left: 0;
    display: block;

    @include breakpoint-down(tablet) {
      position: static;
      text-align-last: left;
    }

    li {
      display: block;
      width: 60px;
      height: auto;
      margin: 0 0 16px;

      @include breakpoint-down(tablet) {
        display: inline-block;
        margin: 0 16px 0 0;
      }

      a {
        display: block;
        width: 60px;
        height: 66px;
      }

      &.slick-active {
        a > span {
          box-shadow: 0 0 1px 1px #000;
        }
      }
    }
  }
}

.theme-top__details {
  position: relative;
  flex: 0 0 32.5301%;
  max-width: 32.5301%;

  @include breakpoint-down(tablet) {
    max-width: 100%;
  }

  .theme-top__details-inner {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: -60px;
    left: 50%;
    width: 200vw;
    height: calc(100% - 40px);
    background-color: $color-light-f0;
    transform: translateX(-50%);

    @include breakpoint-down(tablet) {
      display: none;
    }
  }

  h3 {
    margin-bottom: 17px;
    color: $color-black-07;
  }

  p {
    margin-bottom: 16px;
    color: $color-black-07;
    line-height: 1.6;
  }

  h6 {
    margin-bottom: 26px;
    color: $color-black-07;
  }

  .btn {
    margin-bottom: 16px;
  }
}

.theme-top__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.theme-top__variants {
  display: flex;
  gap: 32px;
  margin-bottom: 40px;
  color: $color-black-07;

  @include breakpoint-down(mobile) {
    margin-bottom: 25px;
  }

  button {
    display: flex;
    gap: 12px;
    align-items: center;
    background: none;
    border: 0;
  }

  .variant-color {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid currentColor;
  }

  .is-active {
    .variant-color {
      border-color: #ffffff;
      box-shadow: 0 0 0 1px currentColor;
    }
  }
}

.theme-top__details-list {
  padding-top: 16px;
  margin-bottom: 80px;

  @include breakpoint-down(mobile) {
    margin-bottom: 50px;
  }

  small {
    display: block;
    margin-bottom: 5px;
    font-size: rem(12.8);
    color: $color-black-07;
    font-weight: 500;

    @include breakpoint-down(mobile) {
      margin-bottom: 16px;
    }
  }

  ul {
    list-style: none;
    font-size: rem(12.8);
    line-height: 1.8;
  }

  li {
    margin-bottom: 5px;

    @include breakpoint-down(mobile) {
      margin-bottom: 18px;
    }
  }
}

.theme-top__links-title {
  margin-bottom: 18px;
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.6;
  color: $color-black-07;

  @include breakpoint-down(mobile) {
    margin-top: 64px;
    margin-bottom: 14px;
    font-size: rem(12.8);
  }
}

.theme-top__links {
  display: flex;
  gap: 24px;

  a {
    position: relative;
    display: inline-block;
    font-size: rem(16);
    text-decoration: none;
    color: $color-blue-30;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 1px;
      transform: translateX(-50%);
      background-color: $color-blue-30;
      transition: width 0.3s;
    }

    &:hover:after {
      width: 0;
    }
  }
}
