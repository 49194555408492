.popular-articles {
  margin-top: 96px;

  @include breakpoint-down(mobile) {
    margin: 48px 0 -62px;
  }

  .popular-articles__inner {
    padding-top: 96px;
    border-top: 1px solid $color-grey-e0;

    @include breakpoint-down(mobile) {
      padding-top: 48px;
    }

    .container {
      padding: 0;
    }
  }

  h4 {
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.4;
    color: #000;

    @include breakpoint-down(mobile) {
      margin-bottom: 24px;
      text-align: left;
    }
  }
}
