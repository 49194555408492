.form-topic-search {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 34px;

  @include breakpoint-down(mobile) {
    margin-top: 16px;
  }

  .form-control {
    flex: 1;

    .field {
      width: 100%;
      padding: 19px 10px 19px 60px;
      font-size: rem(16);

      @include breakpoint-down(mobile) {
        padding: 17px 10px 17px 50px;
        font-size: rem(12.8);
      }
    }
  }

  .form-topic-search__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    background-color: #000;
    border: 0;
    cursor: pointer;
  }

  .icon-search {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint-down(mobile) {
      left: 15px;
    }
  }
}
