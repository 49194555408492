.container {
  max-width: 1440px;
  padding: 0 56px;
  margin: 0 auto;

  @include breakpoint-down(tablet) {
    padding: 0 35px;
  }

  @include breakpoint-down(mobile) {
    padding: 0 24px;
  }

  &--small {
    max-width: 1220px;
  }
  &--full {
    max-width: 100%;
  }
}
