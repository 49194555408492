.section-numbers {
  padding: 100px 0;
  background: $color-black-07;

  @include breakpoint-down(mobile) {
    padding: 0;
  }
}

.number {
  display: flex;
  justify-content: center;
  font-size: rem(61.03);
  font-weight: 700;
  line-height: 1.4;
  color: $color-white;
  text-align: center;

  @include breakpoint-down(tablet) {
    font-size: rem(48);
  }

  @include breakpoint-down(mobile) {
    margin-top: -6px;
    font-size: rem(39.06);
  }
}

.number-text {
  margin-top: 11px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;

  @include breakpoint-down(mobile) {
    margin-top: 7px;
  }
}
