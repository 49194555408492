.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: transparent;
  z-index: 1;
  opacity: 0.8;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  border: 0;
}

/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: "";
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}
