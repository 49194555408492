.banner {
  position: relative;
  height: 750px;
  margin-bottom: 48px;

  @include breakpoint-down(mobile) {
    height: 550px;
    margin-bottom: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 411px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.5;
    z-index: 1;
  }

  .banner__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    color: #fff;
    z-index: 2;
  }

  .banner__text-inner {
    padding: 0 0 64px;
    @include breakpoint-down(mobile) {
      padding: 0 0 40px;
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  h4 {
    @include breakpoint-down(mobile) {
      margin-bottom: 16px;
    }
  }

  h6 {
    margin-bottom: 16px;
    font-size: rem(16);

    @include breakpoint-down(mobile) {
      grid-row: 2;
      margin: 0;
    }
  }
}
