.btn-link {
  display: inline-flex;
  align-items: center;
  font-size: rem(16);
  font-weight: 500;
  text-decoration: none;

  @include breakpoint-down(mobile) {
    font-size: rem(12.8);
  }

  &:hover {
    .btn-link__arrow {
      transform: translateX(5px);
    }
  }
}

.btn-link__arrow {
  transition: transform 0.3s;
  margin-left: 12px;

  path {
    fill: currentColor;
  }
}
