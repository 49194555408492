.contact-row {
  position: relative;
  display: flex;
  justify-content: space-between;

  @include breakpoint-down(mobile) {
    flex-wrap: wrap;
    margin-top: 45px;
  }

  h4 {
    margin-bottom: 27px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 20px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 39.7389%;
    width: 1px;
    height: 100%;
    background-color: $color-grey-e0;

    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  .contact-row__left {
    flex: 0 0 49.3213%;
    max-width: 49.3213%;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .contact-row__right {
    flex: 0 0 28.9593%;
    max-width: 28.9593%;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      flex: 0 0 100%;
      order: -1;
      padding-bottom: 30px;
      margin-bottom: 40px;
      border-bottom: 1px solid $color-grey-e0;
    }
  }
}
