.notice {
  padding: 10px 0;
  background-color: #fff;
  text-align: center;
  color: $color-black-07;
  font-size: rem(12.8);
  font-weight: 500;

  @include breakpoint-down(tablet) {
    padding: 4px 0;
    font-size: rem(10.2);
  }

  a {
    position: relative;
    display: inline-block;
    color: $color-blue-30;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      transition: all 0.3s;
      transform: translateX(-50%);
    }

    &:hover {
      &:after {
        width: 0;
      }
    }
  }
}
