.breadcrumbs {
  margin-top: 48px;
  margin-bottom: 64px;

  @include breakpoint-down(tablet) {
    margin-top: 45px;
  }

  @include breakpoint-down(mobile) {
    display: none;
  }

  ul {
    display: flex;
    gap: 29px;
  }

  li {
    display: flex;
    align-items: center;

    &:after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 18px;
      border: 1px solid $color-black-33;
      transform: rotate(45deg);
      border-bottom: 0;
      border-left: 0;
    }

    &:last-child:after {
      display: none;
    }

    a {
      text-decoration: none;
      color: $color-base-5e;
      transition: all 0.3s;
      text-transform: capitalize;

      &:hover {
        color: #000;
      }
    }

    span {
      text-transform: capitalize;
    }

    &:first-child {
      a {
        color: #000;
      }
    }
  }
}
