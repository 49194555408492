.logos {
  padding: 100px 0 108px;
  text-align: center;

  .container--small {
    max-width: 1380px;
  }

  @include breakpoint-down(tablet) {
    padding: 35px 0;
    margin: 0 0 35px;
  }

  @include breakpoint-down(mobile) {
    padding: 48px 0;
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin-bottom: 60px;

    @include breakpoint-down(mobile) {
      margin-bottom: 43px;
    }
  }
}

.logos__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 15px;
  row-gap: 60px;

  @include breakpoint-down(mobile) {
    column-gap: 20px;
    row-gap: 47px;
  }

  .logo-icon {
    position: relative;
    padding-bottom: 20%;
  }

  @include breakpoint-down(mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .logo {
    position: relative;
    padding-bottom: 2.9%;
    opacity: 0.5;
    transition: opacity 0.3s;

    @include breakpoint-down(mobile) {
      padding-bottom: 0;
      max-height: 46px;
    }

    &:hover {
      opacity: 1;
    }

    img {
      @include breakpoint-down(mobile) {
        max-width: 81px;
        height: auto;
      }
    }
  }
}

.header__inner:not(.header__inner--dark) {
  .logo {
    svg {
      path {
        fill: #000;
      }
    }
  }
}
