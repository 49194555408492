.form-contact {
  margin-top: 34px;

  @include breakpoint-down(mobile) {
    margin-top: 25px;
  }

  label {
    display: block;
    margin-bottom: 18px;
    font-weight: 500;
    color: #000;

    @include breakpoint-down(mobile) {
      margin-bottom: 13px;
    }

    span {
      font-weight: normal;
    }
  }

  .form-control {
    margin-bottom: 36px;

    @include breakpoint-down(mobile) {
      margin-bottom: 25px;
    }
  }

  .field {
    width: 100%;
  }

  .form-contact__btn {
    margin-top: -10px;

    @include breakpoint-down(mobile) {
      margin-top: 0;
      width: 100%;
    }
  }
}
