.nav {
  @include breakpoint-down(tablet) {
    display: none;
  }
  ul {
    display: flex;
    list-style: none;
    align-items: center;
  }

  li + li {
    margin-left: 40px;

    @include breakpoint-down(small-desktop) {
      margin-left: 20px;
    }

    @include breakpoint-down(tablet) {
      margin-right: 10px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    color: #fff;
    text-decoration: none;

    &:not(.btn):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 1px;
      background-color: #fff;
      transition: width 0.3s;

      @include breakpoint-down(tablet) {
        background-color: #000;
      }
    }

    &.is-active,
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

.header__inner:not(.header__inner--dark) {
  .nav {
    a:not(.btn) {
      color: $color-black-07;

      &:after {
        background-color: $color-black-07;
      }
    }
  }
}
