.side-content {
  @include breakpoint-down(mobile) {
    position: relative;
    margin-top: 48px;
    padding-bottom: 0;
  }

  .side-content__title {
    margin-bottom: 48px;
    color: #000;

    @include breakpoint-down(mobile) {
      margin-bottom: 24px;
    }
  }
}

.side-content__flex {
  display: flex;
  justify-content: space-between;
  gap: 11.6%;

  @include breakpoint-down(mobile) {
    display: block;
  }

  h5 {
    margin-bottom: 16px;
    color: #000;
  }

  p {
    margin-bottom: 20px;
  }

  .sidebar {
    flex: 0 0 320px;
    max-width: 320px;
    padding-right: 32px;
    border-right: 1px solid $color-grey-e0;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      padding: 0;
      margin-bottom: 40px;
      border: 1px solid $color-grey-e0;
    }

    .sidebar__mobile {
      display: none;

      @include breakpoint-down(mobile) {
        display: block;
        position: relative;
      }

      button {
        width: 100%;
        border: 0;
        background: none;
        text-align: left;

        @include breakpoint-down(mobile) {
          position: relative;
          display: block;
          padding: 20px;
          font-size: rem(12.8);
          font-weight: 500;
          color: #000;
        }

        &.active {
          position: absolute;
          right: 0;
          top: 29px;
          padding: 0;

          span {
            &:after {
              display: none;
            }
          }
        }

        span {
          position: absolute;
          right: 16px;
          top: 50%;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);

          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: #000;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:after {
            width: 2px;
            height: 14px;
          }

          &:before {
            width: 14px;
            height: 2px;
          }
        }
      }
    }

    .button-holder {
      margin-bottom: 30px;

      @include breakpoint-down(mobile) {
        display: none;
        margin: 0;
        padding: 16px;

        &.active {
          display: block;
        }
      }
    }

    .button {
      background: none;
      border: 0;
      font-size: rem(16);
      text-align: left;
      cursor: pointer;
      font-weight: 400;
      text-decoration: none;
      color: $color-base-5e;

      &:hover {
        color: #000;
      }

      &.is-active {
        color: #000;
      }

      @include breakpoint-down(mobile) {
        font-size: rem(12.8);
      }
    }

    .btn {
      margin-top: 10px;

      @include breakpoint-down(mobile) {
        display: none;
      }
    }

    ul {
      margin-top: 16px;
      margin-left: 40px;
      list-style: none;

      li {
        margin-bottom: 17px;

        .button {
          font-size: rem(12.8);
        }
      }
    }
  }

  .content {
    flex: 1;
  }

  .content_item {
    padding-bottom: 64px;
    border-bottom: 1px solid $color-grey-e0;

    @include breakpoint-down(mobile) {
      padding-bottom: 40px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 24px;
      color: #000;

      @include breakpoint-down(mobile) {
        margin-bottom: 16px;
      }
    }

    h6 {
      margin-top: 64px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-grey-e0;
      margin-bottom: 24px;

      @include breakpoint-down(mobile) {
        margin-top: 40px;
        margin-bottom: 16px;
        padding-bottom: 16px;
      }
    }

    ul,
    ol {
      margin-bottom: 24px;
      list-style-position: inside;

      @include breakpoint-down(mobile) {
        margin-bottom: 16px;
      }
    }

    .btn {
      @include breakpoint-down(mobile) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}
