.common-list {
  position: relative;

  h6 {
    margin-bottom: 24px;
    font-weight: 700;
    color: #000;
  }

  .common-list__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    h6 {
      margin-bottom: 0;
    }
  }

  .btn-link {
    @include breakpoint-down(mobile) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .common-list__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
    row-gap: 24px;
    list-style: none;

    @include breakpoint-down(tablet) {
      gap: 15px;
    }

    @include breakpoint-down(mobile) {
      grid-template-columns: 1fr;
      padding-bottom: 25px;
      gap: 16px;
    }

    li {
      padding-bottom: 26px;
      border-bottom: 1px solid $color-grey-e0;
      font-size: rem(16);
      line-height: 1.8;

      @include breakpoint-down(tablet) {
        padding-bottom: 12px;
      }

      @include breakpoint-down(mobile) {
        padding-bottom: 16px;
        font-size: rem(12.8);
      }

      &.no-border {
        border-bottom: 0;
      }
    }

    a {
      text-decoration: none;
      transition: opacity 0.3s;
      color: #000;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
