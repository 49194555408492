.alternate__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 160px;

  @include breakpoint-down(tablet) {
    display: block;
    margin-bottom: 64px;
  }

  .alternate__box {
    flex: 0 0 50%;
    max-width: 50%;

    @include breakpoint-down(tablet) {
      max-width: 100vw;
    }
  }

  .alternate__box--image {
    @include breakpoint-down(tablet) {
      width: calc(100% + 50px);
      margin: 0 -25px 32px;
    }

    @include breakpoint-down(mobile) {
      margin-bottom: 26px;
    }

    &.alternate__box--right {
      order: 1;
    }
  }

  .alternate__box--content {
    padding: 0 110px;

    @include breakpoint-down(tablet) {
      padding: 0;
    }
  }

  h3 {
    margin-bottom: 24px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 23px;
    }
  }

  p {
    font-size: rem(20);
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }
  }

  .alternate__item-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: rem(16);
    font-weight: 500;
    color: $color-black-07;
  }

  .alternate__subtitle {
    margin-bottom: 24px;
    font-size: rem(12.8);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .alternate__item {
    margin-top: 49px;
    margin-bottom: 69px;

    @include breakpoint-down(mobile) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      font-size: rem(12.8);
      line-height: 1.8;
    }
  }

  .alternate__item + .alternate__item {
    margin-bottom: 0;
  }

  .alternate__item-icon {
    margin-right: 8px;
  }

  .btn-link {
    margin-top: 54px;

    @include breakpoint-down(mobile) {
      margin-top: 30px;
      font-size: rem(16);
    }
  }

  &.alternate__layout {
    margin-bottom: 0;
    .alternate__box--image {
      flex: 0 0 40%;
      max-width: 100%;
      aspect-ratio: 3 / 4;
      * {
        width: 100%;
        height: auto;
        max-height: 100%;
        object-position: center;
        object-fit: cover;
      }
      @include breakpoint-up(mobile) {
        z-index: 1;
      }
      @include breakpoint-down(mobile) {
        margin: 0 0 1rem;
      }
      &.alternate__box--right {
        @include breakpoint-up(mobile) {
          transform: translateX(-25%);
          z-index: 0;
        }
      }
    }
    .alternate__box--content {
      flex: 0 0 70%;
      max-width: 100%;
      width: 100%;
      aspect-ratio: 1;
      height: auto;
      background-color: #f0f1fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint-up(mobile) {
        padding: 0 110px 0 220px;
        transform: translateX(-12.5%);
      }
      &.alternate__box--right {
        @include breakpoint-up(mobile) {
          padding: 0 220px 0 110px;
          transform: translateX(0%);
          z-index: 0;
        }
      }
    }
  }
}

.alternate .container:has(.alternate__layout) {
  @include breakpoint-down(mobile) {
    background-color: #f0f1fa;
    padding-top: 40px;
    padding-bottom: 80px;
  }
}
