.video-wrapper--full,
.container {
  position: relative;
  margin: 0 auto;

  .video-wrapper__inner {
    padding-bottom: 56.2%;
    position: relative;
  }

  .video-wrapper__abs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-wrapper__inner {
  position: relative;
  margin: 0 auto 160px;

  @include breakpoint-down(tablet) {
    margin-bottom: 70px;
  }

  @include breakpoint-down(mobile) {
    display: none;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.dynamic-section {
  .video-wrapper {
    .container {
      @include breakpoint-down(mobile) {
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
    }
    .video-wrapper__inner {
      margin-bottom: 118px;

      @include breakpoint-down(mobile) {
        display: block;
        margin-bottom: 62px;
      }
    }
  }
}
