.social-list {
  display: flex;
  list-style: none;

  li {
    margin-right: 16px;
  }

  a {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
