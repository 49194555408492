.description {
  padding: 102px 0 54px;

  .container {
    max-width: 1220px;
  }

  @include breakpoint-down(mobile) {
    padding: 48px 0;
  }

  .description__inner {
    display: flex;
    justify-content: space-between;
    gap: 148px;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  .description__text {
    margin-bottom: 47px;

    @include breakpoint-down(mobile) {
      margin-bottom: 28px;
    }
  }

  .description__side {
    flex: 0 0 27%;

    @include breakpoint-down(mobile) {
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 8px;
      font-size: rem(16);

      @include breakpoint-down(mobile) {
        margin-bottom: 11px;
        font-size: rem(12.8);
      }
    }
  }

  .description__text-title {
    margin-bottom: 8px;
    font-size: rem(16);
    opacity: 0.6;

    @include breakpoint-down(mobile) {
      margin-bottom: 12px;
      font-size: rem(12.8);
    }
  }

  .description__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20px;
    }
    ul,
    ol {
      font-size: rem(20);
      margin-left: 20px;
      margin-bottom: 40px;

      @include breakpoint-down(mobile) {
        font-size: rem(16);
        line-height: 1.8;
        margin-bottom: 28px;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: rem(20);
      line-height: 1.8;

      @include breakpoint-down(mobile) {
        font-size: rem(16);
        margin-bottom: 28px;
        line-height: 1.8;
      }

      &:last-child {
        @include breakpoint-down(mobile) {
          margin-bottom: 0;
        }
      }
    }
  }
}
