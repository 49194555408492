.modal {
  position: fixed;
  padding: 10vh 10vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  @include breakpoint-down(mobile) {
    padding: 5vh 5vw;
  }

  .modal__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 0;
    background: none;
  }

  &.is-active {
    z-index: 99;

    .modal__close {
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: #fff;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal__overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .modal__inner {
      &:after {
        border: 2px solid #fff;
        border-top-color: transparent;
        border-bottom-color: transparent;
        animation: rotating 1s linear infinite;
      }
    }
  }

  &.is-loaded {
    .modal__inner {
      &:after {
        display: none;
      }
    }
  }

  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s;
  }

  .modal__inner {
    position: relative;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
