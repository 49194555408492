.cta-row {
  display: flex;
  gap: 16px;

  .btn {
    @include breakpoint-down(mobile) {
      width: 160px;
      min-width: 0;
    }
  }
}
