.custom-select {
  &.select-search {
    width: 135px;
    margin-bottom: 0;
  }

  .select-search__input {
    background: transparent;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 37px;
  }

  &.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: transparent;
  }

  .select-search__value::after {
    width: 9px;
    height: 9px;
    margin-top: 2px;
  }

  &.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    border-right-color: $color-white;
    border-bottom-color: $color-white;
  }

  .select-search__select {
    background: $color-black-07;
  }

  .select-search__options {
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.05);
  }

  .select-search__row:not(:first-child) {
    border-top-color: $color-black-07;
  }

  .select-search__option,
  .select-search__not-found {
    background: transparent;
  }

  .select-search__option.is-selected,
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: $color-blue-30;
  }
}
