.text-block {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--increase-space {
    padding: 100px 0;

    @include breakpoint-down(mobile) {
      padding: 48px 0 35px;
    }
  }
}

.text-block__inner {
  max-width: 65%;
  margin: 160px 0;

  &--small {
    max-width: 630px;
    margin: 0 auto;
  }

  &--large {
    max-width: 968px;
    margin: 160px auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
    color: #000;
  }

  h2 {
    margin-bottom: 15px;
    color: #000;
  }

  h3 {
    margin-bottom: 16px;
    line-height: 1.400154;
    color: $color-black-07;
  }

  h4 {
    margin-bottom: 40px;
    color: #000;
  }

  ul,
  ol {
    margin-bottom: 32px;
    margin-left: 23px;
    font-size: rem(20);

    @include breakpoint-down(mobile) {
      margin-bottom: 20px;
      font-size: rem(16);
    }
  }

  blockquote,
  p {
    margin-bottom: 32px;
    line-height: 1.5;
    font-size: rem(20);

    @include breakpoint-down(mobile) {
      margin-bottom: 20px;
      font-size: rem(16);
    }
  }

  code {
    background: $color-light-f0;
    word-wrap: break-word;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem 0.2rem;
    border-radius: 0.2rem;
  }

  hr {
    border: 0;
    height: 1px;
    background: #333;
    margin: 20px 0;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid $color-blue-30;
    margin: 0 10px 10px;
    padding: 10px;
    quotes: "\201C""\201D""\2018""\2019";
  }

  blockquote p {
    display: inline;
  }

  .btn {
    min-width: 180px;
  }
}

.dynamic-section {
  .text-block--mobile-left {
    @include breakpoint-down(mobile) {
      text-align: left;
    }
  }
  .text-block--mobile-center {
    @include breakpoint-down(mobile) {
      text-align: center;
    }
  }
  .text-block--mobile-right {
    @include breakpoint-down(mobile) {
      text-align: right;
    }
  }
  .text-block__inner {
    @include breakpoint-down(tablet) {
      margin: 64px 0;
      max-width: 100%;
    }

    &--small {
      @include breakpoint-down(tablet) {
        max-width: 100%;
        margin: 0 auto;
      }
    }

    &--large {
      @include breakpoint-down(tablet) {
        max-width: 100%;
        margin: 64px auto;
      }
    }

    h2 {
      @include breakpoint-down(mobile) {
        font-size: rem(25);
      }
    }

    h4 {
      @include breakpoint-down(mobile) {
        font-size: rem(25);
        margin-bottom: 24px;
        line-height: 1.4;
      }
    }

    a:not(.btn) {
      position: relative;
      display: inline-block;
      text-decoration: none;
      color: $color-blue-30;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 1px;
        background-color: $color-blue-30;
        transition: width 0.3s;
        transform: translateX(-50%);
      }

      &:hover:after {
        width: 0;
      }
    }
  }
}

.main--dark {
  .text-block__inner h3 {
    color: #fff;
  }
}
