.footer {
  padding: 79px 0 32px;
  margin-top: auto;
  background-color: $color-black-07;
  color: #fff;

  @include breakpoint-down(mobile) {
    padding: 45px 0 32px;
  }

  .footer__logo {
    margin-bottom: 52px;

    @include breakpoint-down(mobile) {
      width: 98px;
      height: 24px;
      margin-bottom: 32px;

      svg {
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }
  }

  h6 {
    margin-bottom: 20px;

    @include breakpoint-down(mobile) {
      margin-bottom: 15px;
    }
  }

  &--alt {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.05);
    }

    .footer__inner {
      position: relative;
      z-index: 2;
    }

    .field--fill {
      background-color: transparent;
    }
  }
}

.footer__inner {
  display: flex;
  justify-content: space-between;

  @include breakpoint-down(mobile) {
    display: block;
  }

  .footer__col {
    flex-grow: 1;

    @include breakpoint-down(mobile) {
      margin-bottom: 40px;
    }

    &:last-child {
      @include breakpoint-down(mobile) {
        margin-bottom: 0;
      }
    }
  }

  .menu-row {
    display: flex;
    justify-content: flex-end;

    @include breakpoint-down(mobile) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.footer__row {
  position: relative;
  z-index: 2;
}

.footer__menu {
  flex: 0 0 220px;

  @include breakpoint-down(small-desktop) {
    flex: 1 1 auto;
  }

  @include breakpoint-down(mobile) {
    margin-bottom: 22px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 18px;

    @include breakpoint-down(mobile) {
      margin-bottom: 22px;
    }
  }

  a {
    font-size: rem(12.8);
    color: $color-light-f0;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  p {
    font-weight: 500;
    margin-bottom: 16px;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }
  }
}
