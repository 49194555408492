.section-team {
  padding: 100px 0;
  background: $color-black-07;
  color: $color-white;

  h3 {
    margin-bottom: 64px;
    text-align: center;

    @include breakpoint-down(mobile) {
      margin-bottom: 31px;
      font-size: rem(31.25);
      line-height: 1.4;
    }
  }

  .section__actions {
    text-align: center;
  }

  .btn {
    @include breakpoint-down(mobile) {
      max-width: 312px;
      width: 100%;
    }
  }
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -8px;
}
  
.team__member {
  max-width: 300px;
  padding: 0 8px;
  margin-bottom: 63px;
  text-align: center;
  
  @include breakpoint-down(mobile) {
    width: 50%;
    flex: 0 1 50%;
    margin-bottom: 32px;
  }

  .team__member-photo {
    position: relative;
    overflow: hidden;
    border-radius: 230px 230px 0px 0px;
    margin-bottom: 10px;

    @include breakpoint-down(mobile) {
      margin-bottom: 12px;
    }
  }

  .team__member-photo span {
    box-shadow: 0 2px 0 $color-blue-30;
  }

  .team__member-name {
    margin-bottom: 3px;
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.4;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }
  }
  
  .team__member-position {
    line-height: 1.6;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
    }
  }
}

