.article-box {
  .article__image {
    margin-bottom: 11px;
    opacity: 1;
    transition: opacity .3s;

    &:hover {
      opacity: 0.75;
    }
  }

  .article__image a {
    display: block;
  }

  .article__title {
    line-height: 1.62;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
      line-height: 1.8;
    }
  }

  .article__title a {
    color: $color-black-07;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .slider-element__slide & .article__title a {
    color: #fff;
  }
}
