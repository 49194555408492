.slider-element {
  position: relative;
  padding: 100px 0;

  @include breakpoint-down(mobile) {
    padding: 48px 0;
  }

  &.has-side-space {
    padding-left: 24px;

    @include breakpoint-down(mobile) {
      padding-left: 18px;
    }
  }

  h2 {
    max-width: 769px;
    margin-bottom: 32px;
  }

  .container > a {
    margin-bottom: 64px;
  }

  .slider-element__slide {
    &--image {
      display: flex;
      align-items: center;
      padding: 0 69px;
      max-width: 774px;
      flex: 0 0 auto;

      @include breakpoint-down(mobile) {
        padding: 0 12px;
        width: 260px;
        max-width: 260px;
      }
    }

    &--project {
      padding-right: 32px;

      @include breakpoint-down(mobile) {
        padding: 0 8px;
      }
    }

    &--testimonial {
      padding: 48px 0;
      flex: 1 0 100%;
    }
  }

  .slider-element__slide-inner {
    position: relative;
    display: block;
    width: 550px;
    height: 799px;

    @include breakpoint-down(mobile) {
      width: 280px;
      height: 407px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.5;
      z-index: 1;
    }

    &:hover {
      .slider-element__slide-text {
        p {
          max-height: 60px;
          opacity: 1;
        }
      }
    }
  }

  .slider-element__slide-text {
    position: absolute;
    bottom: 45px;
    left: 0;
    width: 100%;
    padding: 0 48px;
    z-index: 2;

    @include breakpoint-down(mobile) {
      padding: 0 24px;
      bottom: 24px;
    }

    .h4 {
      margin-bottom: 16px;

      @include breakpoint-down(tablet) {
        margin-bottom: 12px;
      }

      @include breakpoint-down(mobile) {
        margin-bottom: 10px;
      }
    }

    p {
      max-height: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      font-weight: 500;

      @include breakpoint-down(mobile) {
        max-height: none;
        opacity: 1;
      }
    }
  }

  .slider-element__badge {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 32px;
    top: 0;
    right: 0;
    z-index: 1;
    gap: 8px;
    font-size: 0;

    @include breakpoint-down(mobile) {
      padding: 16px;
    }

    .slider-element__badge-inner {
      text-align: right;
    }

    span {
      display: inline-block;
      padding: 6px 12px;
      border: 1px solid $color-blue-30;
      background-color: $color-blue-30;
      font-size: rem(12.8);
      color: #fff;

      @include breakpoint-down(mobile) {
        font-size: rem(10.24);
      }
    }
  }

  .slider-element__slide--article {
    margin-right: 40px;

    @include breakpoint-down(mobile) {
      margin-right: 24px;
    }

    .slider-element__slide-inner {
      width: 432px;
      max-width: 100%;
      height: auto;

      @media (max-width: 520px) {
        width: 77.78vw;
      }

      &:before {
        display: none;
      }
    }

    .article__meta {
      color: rgba(255, 255, 255, 0.5);
    }

    .article__meta .article__meta-tags span + span:before {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.embla {
  position: relative;
}

.embla__viewport {
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
  min-width: 50%;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 190px;
}
