.section-accordion {
  padding: 155px 0 71px;
  background: $color-black-07;
  color: $color-white;

  @include breakpoint-down(small-desktop) {
    padding-top: 67px;
  }

  @include breakpoint-down(mobile) {
    padding-bottom: 10px;
  }
}

.accordion__section {
  &.current {
    .accordion__head {
      padding-bottom: 15px;

      @include breakpoint-down(mobile) {
        padding-bottom: 12px;
      }
    }
  }
}

.accordion {
  font-size: rem(14);
  line-height: 1.8;
  color: $color-white;

  @include breakpoint-down(mobile) {
    font-size: rem(12.8);
  }

  p {
    line-height: inherit;
  }

  .accordion__section:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .accordion__head {
    position: relative;
    padding: 39px 0 33px;

    @include breakpoint-down(mobile) {
      padding: 25px 0 24px;
    }
  }

  .accordion__head-title {
    margin-bottom: 9px;
    font-size: rem(20);
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      margin-bottom: 7px;
      font-size: rem(16);
    }
  }

  .accordion__head-subtitle {
    font-size: rem(16);
    line-height: 1.6;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
      line-height: 1.8;
    }
  }

  .accordion__head-action {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .accordion__head-icon {
    position: absolute;
    top: 36px;
    right: 4px;
    display: block;
    width: 50px;
    height: 50px;
    background: url(../../../assets/images/svg/icon-accordiion-arrow.svg)
      center/100% auto no-repeat;
    transform-origin: center;

    @include breakpoint-down(mobile) {
      top: 37px;
      right: 8px;
      width: 30px;
      height: 30px;
    }
  }

  .accordion__animate {
    position: relative;
    overflow: hidden;
  }

  .accordion__body {
    padding-bottom: 52px;
    color: rgba(255, 255, 255, 0.7);

    @include breakpoint-down(mobile) {
      padding-bottom: 24px;
    }
  }

  .accordion__body h6 {
    padding-bottom: 23px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;

    @include breakpoint-down(mobile) {
      padding-bottom: 16px;
      font-size: rem(12.8);
    }
  }

  .accordion__body p {
    padding-bottom: 24px;
    margin-bottom: 0;

    @include breakpoint-down(mobile) {
      padding-bottom: 23px;
    }
  }

  .accordion__body strong {
    font-weight: 500;
  }

  .accordion__body ul {
    padding-bottom: 32px;
    list-style: none;
  }

  .accordion__body ul li {
    position: relative;
    padding-left: 21px;

    @include breakpoint-down(mobile) {
      padding-left: 19px;
    }
  }

  .accordion__body ul li:before {
    content: "";
    position: absolute;
    top: 11px;
    left: 8px;
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
  }
}
