.form-newsletter {
  position: relative;
  display: flex;
  width: 320px;
  margin-bottom: 24px;
  max-width: 100%;

  @include breakpoint-down(mobile) {
    margin-bottom: 15px;
  }

  .form-control {
    flex-grow: 1;
  }

  .field {
    width: 100%;
    color: #fff;
    font-size: rem(12.8);
  }

  ::placeholder {
    color: #fff;
    font-size: rem(12.8);
    opacity: 1;
  }

  .form-newsletter__btn {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100%;
    width: 49px;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    border: 0;

    svg {
      transition: transform 0.3s;
    }

    &:hover {
      svg {
        transform: translateX(3px);
      }
    }
  }
}
