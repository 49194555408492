.section__cols {
  display: flex;
  justify-content: space-between;

  .section__col {
    width: 50%;
    flex: 0 1 50%;
  }
}

.section__cols--fullwidth {
  justify-content: center;

  @include breakpoint-down(mobile) {
    margin: 0 -24px;
  }

  .section__col {
    width: auto;
    flex: 0 1 auto;
  }
}

.section__cols--columns {
  margin: 0 -10px;

  @include breakpoint-down(mobile) {
    display: block;
    margin: 0;
  }

  .section__col {
    width: auto;
    flex: 0 1 auto;
    padding: 0 10px;

    @include breakpoint-down(mobile) {
      width: 100%;
      flex: 0 1 100%;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 64px;
      }
    }
  }
}

/* Accordion */
.section__cols-accordion {
  font-size: rem(20);
  line-height: 1.5;

  @include breakpoint-down(tablet) {
    display: block;
  }

  @include breakpoint-down(mobile) {
    font-size: rem(16);
  }

  h2 {
    margin-bottom: 31px;

    @include breakpoint-down(mobile) {
      margin-bottom: 25px;
    }
  }

  p {
    margin-bottom: 30px;
    line-height: inherit;

    @include breakpoint-down(mobile) {
      margin-bottom: 24px;
    }
  }

  .section__col {
    @include breakpoint-down(tablet) {
      width: 100%;
      flex: 0 1 100%;
    }
  }

  .section__col:first-child {
    max-width: 433px;

    @include breakpoint-down(tablet) {
      max-width: 100%;
    }
  }

  .section__col:last-child {
    margin-top: -32px;
    max-width: 544px;

    @include breakpoint-down(tablet) {
      margin-top: 0;
      max-width: 100%;
    }
  }
}

/* Numbers */
.section__cols-numbers {
  max-width: 1140px;
  margin: 0 auto;

  @include breakpoint-down(mobile) {
    flex-direction: column;
  }

  .section__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 33.333333%;
    width: 33.333333%;
    min-height: 229px;

    @include breakpoint-down(mobile) {
      width: 100%;
      flex: 0 1 100%;
      min-height: 173px;
    }
  }

  .section__col:not(:last-child) {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);

    @include breakpoint-down(mobile) {
      border-width: 0 0 1px;
    }
  }
}
