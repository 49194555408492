.percent-cards {
  .percent-cards__grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include breakpoint-up(mobile) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .percent-cards__cards-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
    @include breakpoint-up(mobile) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1.75rem;
    }
    .percent-card--big {
      @include breakpoint-up(mobile) {
        grid-column: span 2 / span 2;
      }
    }
  }

  .percent-cards__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint-up(mobile) {
      padding-right: 8rem;
      position: sticky;
      top: 10rem;
      height: fit-content;
    }
  }

  .percent-cards__content-up {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include breakpoint-down(mobile) {
      padding-bottom: 2.25rem;
    }
  }

  .percent-cards__title {
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 100%; /* 61px */
    letter-spacing: -2.44px;
    @include breakpoint-down(mobile) {
      font-size: 2rem;
      letter-spacing: -1.252px;
    }
  }

  .percent-cards__body {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    @include breakpoint-down(mobile) {
      font-size: 0.875rem;
      letter-spacing: -1.252px;
    }
  }

  .percent-cards__mobile-text {
    font-size: 25px;
    font-weight: 600;
    line-height: 100%; /* 25px */
    letter-spacing: -1px;
    padding-block: 3rem;
  }

  .percent-cards__end-text {
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    margin-top: 38.5vh;
    @include breakpoint-down(mobile) {
      margin-top: 3rem;
    }
  }

  .percent-cards__mobile {
    @include breakpoint-up(mobile) {
      display: none;
    }
    .percent-cards__end-text {
      font-size: 0.75rem;
    }
  }

  .percent-cards__content .percent-cards__end-text,
  .percent-cards__cards-grid .percent-card:not(.percent-card--percentage) {
    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  .percent-card {
    padding: 3rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint-down(mobile) {
      padding: 1.5rem;
      align-self: center;
      text-align: center;
    }
    .percent-card__percentage {
      color: #d9d9d9;
      font-size: 39.1px;
      font-weight: 600;
      line-height: 100%; /* 39.1px */
      letter-spacing: -1.564px;
    }
    h5 {
      font-size: 20px;
      font-weight: 600;
      line-height: 120%; /* 24px */
      letter-spacing: -0.8px;
      padding-bottom: 0.75rem;
    }
    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    .percent-card__tick {
      display: none;
    }
  }

  .percent-card:not(.percent-card--percentage) {
    h5 {
      @include breakpoint-up(mobile) {
        background: linear-gradient(90deg, #6456d8 0%, #a399f5 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }

  .percent-cards__mobile .percent-card {
    @include breakpoint-down(mobile) {
      padding-block: 1rem;
      padding-inline: 0.5rem;
      border: none;
      background-color: transparent;
      text-align: left;
      align-self: start;
    }
    &:not(:last-child) {
      @include breakpoint-down(mobile) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 160%; /* 22.4px */
    }
    p {
      margin-left: 1.75rem;
    }
    .percent-card__tick {
      display: inline-flex;
      margin-right: 0.25rem;
    }
  }

  .percent-card__progress {
    width: 50%;
    height: 2px;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: relative;
    @include breakpoint-down(mobile) {
      margin-inline: auto;
    }
    .percent-card__progress-line {
      position: absolute;
      height: 2px;
      background: linear-gradient(90deg, #2c1bb8 0%, #6759d9 100%);
      box-shadow: 0px 0px 8px 0px #352b8d;
    }
    .percent-card__progress-bar {
      height: 2px;
      width: 100%;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
