.lang-switch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 13px;
  font-weight: 500;

  @include breakpoint-down(mobile) {
    margin: 0 0 15px;
  }
}
