:root {
  --border: 1px dashed #e5e5e5;
}

.icon-set {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @include breakpoint-up(mobile) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.icon-set__item {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  @include breakpoint-down(mobile) {
    &:nth-child(-n + 3) {
      border-bottom: var(--border);
    }
    &:nth-child(3n + 1) {
      border-right: var(--border);
    }
    &:nth-child(3n) {
      border-left: var(--border);
    }
    &:nth-child(n + 7) {
      border-top: var(--border);
    }
  }

  @include breakpoint-up(mobile) {
    &:nth-child(-n + 4) {
      border-bottom: var(--border);
    }
    &:nth-child(4n + 1),
    &:nth-child(4n + 2) {
      border-right: var(--border);
    }
    &:nth-child(4n) {
      border-left: var(--border);
    }
    &:nth-child(n + 8) {
      border-top: var(--border);
    }
  }
}

.icon-set__item-label {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  bottom: 16px;
  margin: 0 !important;
  font-size: 0.8rem;
}
