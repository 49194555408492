.section-images {
  padding: 100px 0;
  background: $color-black-07;

  @include breakpoint-down(mobile) {
    padding: 48px 0;
  }

  .container--small {
    .section__cols--fullwidth {
      @include breakpoint-down(mobile) {
        margin: 0;
      }
    }
  }
}
