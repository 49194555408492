.text-image {
  margin-bottom: 160px;

  @include breakpoint-down(tablet) {
    margin-bottom: 75px;
  }

  @include breakpoint-down(mobile) {
    margin-bottom: 64px;
  }
}

.text-image__inner {
  display: flex;
  justify-content: space-between;

  @include breakpoint-down(mobile) {
    display: block;
  }

  .text {
    flex: 0 0 52.1572%;
    max-width: 52.1572%;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      margin-bottom: 40px;
    }

    h3 {
      margin: 16px 0;
      color: $color-black-07;
    }
  }

  .image {
    position: relative;
    flex: 0 0 39.1304%;
    max-width: 39.1304%;
    padding-bottom: 34.6%;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      padding-bottom: 63.6%;
    }
  }
}
