.heading-row {
  padding: 100px 0;

  @include breakpoint-down(mobile) {
    padding: 0;
    margin-bottom: 45px;
  }

  .heading-row__inner {
    @include breakpoint-down(mobile) {
      max-width: 100% !important;
    }
  }
}
