.field {
  padding: 14px 16px;
  background: #fff;
  border: 1px solid $color-grey-e0;
  font-size: rem(12.8);
  color: $color-light-f0;
  color: $color-base-5e;

  &--fill {
    background-color: $color-black-07;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0;
  }

  &--message {
    height: 170px;

    @include breakpoint-down(mobile) {
      height: 110px;
    }
  }

  &.error {
    border-color: red;
  }

  &--file {
    position: relative;
    text-align: center;

    .field__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .field__placeholder {
      color: #000;
    }
  }
}
