.share {
  position: absolute;
  top: 68px;
  left: 56px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  font-size: rem(12.8);
  line-height: 1.5;
  font-weight: 500;
  color: $color-base-5e;
  letter-spacing: 1px;
  text-transform: uppercase;

  p {
    margin-bottom: 7px;
  }

  a,
  svg {
    display: block;
  }

  a {
    opacity: 1;
    transition: opacity .3s;

    &:hover {
      opacity: 0.75;
    }
  }

  @include breakpoint-down(tablet) {
    position: static !important;
    align-items: center;
    flex-direction: row;
    max-width: 656px;
    margin: 48px auto 0;

    p {
      margin: 0 7px 0 0;
    }
  }
}
