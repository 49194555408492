.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 63px;

  @include breakpoint-down(mobile) {
    margin-top: 48px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;

    @include breakpoint-down(mobile) {
      justify-content: space-between;
      width: 100%;
      gap: 5px;
    }
  }

  a {
    display: block;
    width: 36px;
    line-height: 37px;
    color: #000;
    text-align: center;
    text-decoration: none;
    transition: color .3s;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
      line-height: 1.6;
    }
  }

  a:hover,
  .current {
    color: $color-blue-30;
  }

  a[aria-label="Previous page"],
  a[aria-label="No previous page available"],
  a[aria-label="Next page"],
  a[aria-label="No next page available"] {
    width: 40px;
    height: 40px;
    border: 1px solid $color-grey-e0;
    background: url(../../../assets/images/svg/pagination-arrow.svg) no-repeat center center;

    svg {
      display: none;
    }
  }

  a[aria-label="Previous page"],
  a[aria-label="No previous page available"] {
    margin-right: 17px;
  }

  a[aria-label="Next page"],
  a[aria-label="No next page available"] {
    margin-left: 17px;
    transform: rotate(180deg);
  }

  .disabled:not(.current) {
    opacity: 0.5;
  }
}

.pagination--blog {
  @include breakpoint-down(mobile) {
    margin-bottom: -32px;
  }
}
