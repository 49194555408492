.support {
  position: relative;
  margin-bottom: 116px;

  @include breakpoint-down(tablet) {
    margin-bottom: 75px;
  }

  &.no-margin {
    @include breakpoint-down(mobile) {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-bottom: 24px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 15px;
    }
  }

  p {
    margin-bottom: 20px;
    font-size: rem(20);
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      margin-bottom: 25px;
      font-size: rem(16);
    }
  }

  &:after {
    @include breakpoint-down(mobile) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 5%);
      background-color: $color-light-f0;
    }
  }
}

.support__inner {
  position: relative;
  padding: 64px 64px 74px;
  background-color: $color-light-f0;
  z-index: 1;

  @include breakpoint-down(tablet) {
    padding: 35px;
  }

  @include breakpoint-down(mobile) {
    padding: 65px 24px;
    margin: 0 -24px;
    background: none;
  }
}

.support__inner-left {
  max-width: 43%;
  min-height: 290px;

  @include breakpoint-down(tablet) {
    max-width: 100%;
    min-height: 0;
  }
}

.support__inner-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 55%;

  @include breakpoint-down(tablet) {
    position: static;
    transform: none;
    max-width: 100%;
  }

  @include breakpoint-down(mobile) {
    display: flex;
    justify-content: flex-end;
  }

  .support-image {
    @include breakpoint-down(mobile) {
      display: flex;
      justify-content: flex-end;
      width: 558px;
      min-width: 558px;
      margin: 0 -80px -120px;
    }
  }
}

.support__inner-bottom-link {
  position: absolute;
  bottom: 64px;
  left: 64px;

  @include breakpoint-down(tablet) {
    position: static;
  }
}
