.mobile-menu__toggle {
  position: relative;
  z-index: 4;
  border: 0;
  padding: 0;
  background: none;
  font-size: 0;
  display: none;

  @include breakpoint-down(tablet) {
    display: block;
  }
}

.mobile-menu__modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 64px 24px;
  background-color: $color-light-f0;
  z-index: 3;
  overflow: auto;

  .nav {
    display: block;
  }

  ul {
    display: block;
    list-style: none;

    li {
      margin: 0 0 32px;
      font-size: rem(20);

      a {
        text-decoration: none;
        color: #000;
      }
    }

    .btn {
      width: 100%;
      color: #fff;

      &:hover {
        background-color: $color-blue-30;
        color: #fff;
      }
    }
  }
}

.mobile-menu__toggle {
  .line {
    fill: none;
    stroke: #fff;
    stroke-width: 4;
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 61 207;
    stroke-width: 4;
  }
  .line2 {
    stroke-dasharray: 61 60;
    stroke-width: 4;
  }
  .line3 {
    stroke-dasharray: 61 207;
    stroke-width: 4;
  }

  &.is-active .line {
    stroke: #000;
  }

  &.is-active .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 4;
  }
  &.is-active .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 4;
  }
  &.is-active .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 4;
  }
}

.header__inner:not(.header__inner--dark) {
  .mobile-menu__toggle {
    .line {
      stroke: #000;
    }
  }
}
