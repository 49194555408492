/* ------------------------------------------------------------ *\
	Growth Page | Layout
\* ------------------------------------------------------------ */
.wrapper--growth {
  .header {
    position: fixed;
    width: 100%;
    z-index: 10;
    .header__inner--dark {
      background-color: transparent !important;
    }
  }
  .main {
    position: relative;
    padding-bottom: 0;
  }
  .footer {
    position: relative;
    z-index: 10;
  }
  .btn {
    font-size: 0.875rem;
    @include breakpoint-down(mobile) {
      font-size: 0.875rem;
    }
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Hero Section
\* ------------------------------------------------------------ */
.hero--growth {
  position: fixed;
  width: 100%;
  aspect-ratio: 16 / 9;
  .container {
    position: relative;
    z-index: 10;
    height: 100%;
    max-height: 780px;
    display: flex;
    align-items: flex-end;
    @include breakpoint-down(mobile) {
      height: 600px;
    }
  }
  .hero--growth__content {
    max-width: 55%;
    margin-bottom: 4rem;
    opacity: 1;
    transition: opacity 0.5s ease;
    p {
      color: #fff;
      font-size: 0.75rem;
      // font-weight: 500;
      line-height: 160%; /* 1.2rem */
      text-transform: uppercase;
      margin-bottom: 0.75rem;
      span.mobile {
        display: none;
      }
      @include breakpoint-down(mobile) {
        span.desktop {
          display: none;
        }
        span.mobile {
          display: inline;
        }
      }
    }
    h1 {
      margin-bottom: 2rem;
      color: #fff;
      font-size: 3.8125rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.17156rem;
      margin-bottom: 2.25rem;
      span:last-child {
        color: rgb(255 255 255 / 0.4);
      }
      @include breakpoint-down(mobile) {
        font-size: 2.44375rem;
        letter-spacing: -0.10994rem;
        span {
          display: none;
        }
      }
    }
    .btn {
      padding: 1rem 1.5rem;
    }
    @include breakpoint-down(mobile) {
      max-width: initial;
      margin-bottom: 2.25rem;
    }
  }
  .hero--growth__video--overlay,
  .hero--growth__video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .hero--growth__video--overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    z-index: 1;
  }
  .hero--growth__video {
    object-fit: cover;
    z-index: 0;
  }
}

/*
 * Scrollover Intro Section
 * All other sections get in to this
 */
.main__scrollover--hero {
  min-height: 100vh;
  position: relative;
  margin-top: 780px;
  // padding-bottom: 96px;
  background-color: #fff;
  z-index: 10;
  @include breakpoint-down(mobile) {
    margin-top: 600px;
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Intro Section
\* ------------------------------------------------------------ */
.intro--growth {
  .intro--growth__text {
    padding-top: 6.75rem;
    margin-bottom: 2.25rem;
    max-width: 69rem;
    * {
      display: inline !important;
    }
    p {
      color: rgba(7, 4, 32, 0.16);
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 120%; /* 3.66rem */
      letter-spacing: -0.122rem;
      .color-toggle {
        color: rgba(7, 4, 32, 0.16);
        transition: color 0.2s;
      }
      span,
      .color-toggle.active {
        color: rgba(7, 4, 32, 1);
      }
      @include breakpoint-down(mobile) {
        font-size: 1.95625rem;
        letter-spacing: -0.07825rem;
      }
    }
    @include breakpoint-down(mobile) {
      padding-top: 4rem;
    }
  }
  .btn {
    padding: 1rem 1.5rem;
  }
  .intro--growth__images {
    margin-top: 6.75rem;
    display: flex;
    gap: 2.25rem;

    @include breakpoint-down(mobile) {
      display: block;
      gap: 0;
      .intro--growth__image--wrapper:first-child {
        display: none;
      }
      .intro--growth__image--wrapper:last-child {
        width: 100%;
        height: 408px;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
          max-width: initial;
        }
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Formula Section
\* ------------------------------------------------------------ */
.formula--growth {
  --hover-transition: 0.5s ease;

  padding-top: 6.5rem;
  padding-bottom: 5.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @include breakpoint-down(mobile) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .container {
    position: relative;
  }
  .formula--growth__title {
    color: #070420;
    font-size: 3.05rem;
    font-weight: 500;
    line-height: 100%; /* 3.05rem */
    letter-spacing: -0.122rem;
    position: absolute;
    max-width: 20rem;
    @include breakpoint-down(mobile) {
      font-size: 2.44375rem;
      letter-spacing: -0.09775rem;
      position: relative;
      max-width: initial;
    }
  }
  .formula--growth__desktop {
    display: flex;
    gap: 8rem;
    .formula--growth__chat {
      align-self: flex-end;
      width: 20rem;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      justify-self: flex-end;
      li {
        position: relative;
        &.formula--growth__chat-client {
          opacity: 1;
          transform: translateY(0px);
          transition: all var(--hover-transition);
        }
        &.formula--growth__chat-digifist {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .fg-chat-message {
            color: #fff;
            background-color: rgb(39, 142, 255);
            width: 199px;
            height: 52px;
            transition: all var(--hover-transition);
            &::after {
              left: initial;
              right: -11px;
              background: url(../../../assets/images/svg/growth-formula-chat-message-right.svg)
                center/100% auto no-repeat;
            }
            .fg-chat-message__current {
              opacity: 0;
              transition: opacity var(--hover-transition) 0.35s;
            }
          }
        }
        &.formula--growth__chat-hidden {
          position: absolute;
          opacity: 0;
          max-width: 320px;
        }
        &.formula--growth__chat-services {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          opacity: 0;
          transform: translateY(100px);
          transition: all var(--hover-transition);
          &.isHidden {
            opacity: 0;
            transform: translateY(100px);
          }
          .fg-chat-username {
            text-transform: uppercase;
          }
          .fg-chat-message {
            color: #000;
            border-radius: 6.1875rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            &::after {
              content: none;
            }
          }
        }
        .fg-chat-username {
          color: #8e8d94;
          font-size: 1rem;
          // font-weight: 400;
          line-height: normal;
          letter-spacing: -0.04rem;
        }
        .fg-chat-message {
          font-size: 1rem;
          // font-weight: 400;
          line-height: 140%; /* 1rem */
          letter-spacing: -0.04rem;
          color: #000;
          background-color: rgb(233, 233, 235);
          padding: 1.12rem 1.5rem;
          border-radius: 1.25rem;
          width: fit-content;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -11px;
            display: block;
            width: 38px;
            height: 37px;
            background: url(../../../assets/images/svg/growth-formula-chat-message-left.svg)
              center/100% auto no-repeat;
            z-index: -1;
          }
        }
      }
    }
    .formula--growth__the-formula {
      color: #d7d7db;
      text-align: center;
      font-size: 5.0625rem;
      font-weight: 500;
      line-height: 120%; /* 6.075rem */
      letter-spacing: -0.2025rem;
      transition: color var(--hover-transition);
      .formula--growth__hover-items {
        display: flex;
        gap: 0.59rem;
        span {
          display: inline-flex;
        }
      }
      .formula--growth__formula-item {
        color: #d7d7db;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        transition: color var(--hover-transition);
        div {
          color: #070420;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          // font-weight: 500;
          line-height: 160%; /* 1.2rem */
          letter-spacing: initial;
          text-transform: uppercase;
          white-space: nowrap;
          transition: color var(--hover-transition);
        }
      }
      .formula--growth__desktop-divider-content {
        color: #070420;
        text-align: left;
        font-size: 5.0625rem;
        font-weight: 500;
        line-height: 120%; /* 6.075rem */
        letter-spacing: -0.2025rem;
        text-transform: uppercase;
        transition: opacity var(--hover-transition);
      }
    }
    @include breakpoint-down(mobile) {
      display: none;
    }
  }
  .formula--growth__desktop.is-hovering {
    .formula--growth__chat {
      .formula--growth__chat-client {
        opacity: 0;
        transform: translateY(-50px);
      }
      .formula--growth__chat-digifist {
        .fg-chat-message {
          width: 320px;
          .fg-chat-message__current {
            opacity: 1;
          }
        }
      }
      .formula--growth__chat-services {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .formula--growth__the-formula {
      color: #eeedef;
      .formula--growth__formula-item {
        color: #eeedef;
        div {
          color: #a8a7b1;
        }
        &:hover {
          color: #070420;
          div {
            color: #070420;
          }
        }
      }
      .formula--growth__desktop-divider-content {
        opacity: 0.07;
        &.first {
          opacity: 0.35 !important;
        }
      }
    }
  }
  .formula--growth__mobile {
    margin-top: 3rem;
    display: none;
    @include breakpoint-down(mobile) {
      display: block;
    }
    .formula--growth__mobile-divider-content {
      color: #070420;
      text-align: center;
      font-size: 1.5625rem;
      font-weight: 500;
      line-height: 120%; /* 1.875rem */
      letter-spacing: -0.0625rem;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .accordion--growth {
      padding: 1.5rem;
      color: #000;
      border-radius: 0.75rem;
      background: rgba(0, 0, 0, 0.02);
      .accordion--growth__item:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .accordion--growth__item:first-child {
        .accordion--growth__head-title span {
          display: none;
        }
      }
      .accordion--growth__head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        cursor: pointer;
      }
      .accordion--growth__head-title {
        margin-bottom: 0;
        color: #343349;
        font-size: 1rem;
        font-weight: 500;
        line-height: 120%; /* 1.2rem */
        letter-spacing: -0.04rem;
        span {
          color: #acacac;
          text-align: center;
          margin-right: 0.5rem;
        }
      }
      .accordion--growth__head-icon {
        position: relative;
        width: 24px;
        height: 24px;
        background: url(../../../assets/images/svg/icon-accordion-growth-arrow.svg)
          center/100% auto no-repeat;
        transform-origin: center;
      }
      .accordion--growth__animate {
        position: relative;
        overflow: hidden;
      }
      .accordion--growth__body {
        color: #343349;
        font-size: 0.875rem;
        // font-weight: 500;
        line-height: 160%; /* 1.4rem */
        letter-spacing: -0.035rem;
        padding-bottom: 24px;
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Our Works Overview Section
\* ------------------------------------------------------------ */
.works-ov--growth {
  padding-top: 6.75rem;
  .works-ov--growth__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr [col-start]);
    column-gap: 8rem;
    row-gap: 1.75rem;
    margin-bottom: 6.75rem;
  }
  .works-ov--growth__title {
    grid-column: 2 / 1;
    grid-row: 2 / 1;
    h5 {
      color: #070420;
      font-size: 3.05rem;
      font-weight: 500;
      line-height: 100%; /* 3.05rem */
      letter-spacing: -0.122rem;
      @include breakpoint-down(mobile) {
        color: #62666b;
        font-size: 0.75rem;
        // font-weight: 500;
        line-height: 160%; /* 1.2rem */
        text-transform: uppercase;
        letter-spacing: initial;
      }
    }
  }
  .works-ov--growth__subtitle {
    grid-column: 2 / 2;
    grid-row: 2 / 1;
    h6 {
      color: #070420;
      font-size: 3.05rem;
      font-weight: 500;
      line-height: 100%; /* 3.05rem */
      letter-spacing: -0.122rem;
      @include breakpoint-down(mobile) {
        font-size: 2.44375rem;
        letter-spacing: -0.09775rem;
      }
    }
  }
  .works-ov--growth__body {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    color: #070420;
    font-size: 1rem;
    // font-weight: 500;
    line-height: 160%; /* 1.6rem */
    letter-spacing: -0.04rem;
  }
  .works-ov--growth__partners {
    grid-column: 2 / 1;
    grid-row: 2 / 2;
    align-self: flex-end;
    h6 {
      color: #62666b;
      font-size: 0.75rem;
      // font-weight: 500;
      line-height: 160%; /* 1.2rem */
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
    .works-ov--growth__partners-logos {
      display: flex;
      gap: 1rem;
      @include breakpoint-down(mobile) {
        display: none;
      }
    }
    .marquee {
      --marquee-gap: 1rem;
      --marquee-duration: 20s;
      @include breakpoint-up(mobile) {
        display: none;
      }
    }
  }
  .works-ov--growth__video {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .works-ov--growth__video--overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      background: url(../../../assets/images/svg/growth-video-play-btn.svg)
        center/6rem auto no-repeat;
      z-index: 10;
      pointer-events: none;
    }
    video {
      width: 100%;
      height: auto;
      background-color: #acacac;
    }
  }
  @include breakpoint-down(mobile) {
    .works-ov--growth {
      padding-top: 4rem;
    }

    .works-ov--growth__grid {
      display: flex;
      flex-direction: column;
      grid-template-columns: initial;
      column-gap: initial;
      row-gap: initial;
      margin-bottom: 4rem;
    }
    .works-ov--growth__title,
    .works-ov--growth__subtitle,
    .works-ov--growth__body,
    .works-ov--growth__partners {
      grid-column: initial;
      grid-row: initial;
      align-self: initial;
    }

    .works-ov--growth__title {
      margin-bottom: 1rem;
    }
    .works-ov--growth__subtitle {
      margin-bottom: 1.75rem;
    }
    .works-ov--growth__body {
      margin-bottom: 5.5rem;
    }
    .works-ov--growth__partners {
      margin-bottom: 2rem;
    }
    .works-ov--growth__video {
      aspect-ratio: initial;
      video {
        width: initial;
        height: initial;
        min-height: 20rem;
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | 360 Growth Section
\* ------------------------------------------------------------ */
.circle--growth {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .container {
    max-width: calc(55rem + 112px);
  }
  .circle--growth__main-content {
    h5 {
      color: #070420;
      font-size: 5rem;
      font-weight: 500;
      line-height: 100%; /* 5rem */
      letter-spacing: -0.225rem;
      margin-bottom: 3rem;
      @include breakpoint-down(mobile) {
        font-size: 2.44375rem;
        letter-spacing: -0.10994rem;
      }
    }
    p {
      color: #070420;
      font-size: 2.44375rem;
      // font-weight: 500;
      line-height: 120%; /* 2.9325rem */
      letter-spacing: -0.09775rem;
      margin-bottom: 5.5rem;
      @include breakpoint-down(mobile) {
        font-size: 1.5625rem;
        letter-spacing: -0.0625rem;
      }
    }
  }
  .circle--growth__hover-info {
    margin: 0 auto;
    padding-top: 2rem;
    max-width: 27rem;
    min-height: 6rem;
    color: #8c8c8c;
    text-align: center;
    font-size: 0.875rem;
    // font-weight: 500;
    line-height: 160%; /* 1.4rem */
    letter-spacing: -0.035rem;
    opacity: 0;
    transition: all 0.3s ease;
    &.isHovering {
      opacity: 1;
    }
    @include breakpoint-down(mobile) {
      display: none;
    }
  }
  .circle--growth__animated {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    height: 460px;

    .circle--growth__lottie {
      display: block;
      position: relative;
      width: 100%;
      @include breakpoint-down(mobile) {
        display: none;
      }
      .circle--growth__content-wrapper {
        position: absolute;
        top: 12.5%;
        right: 4%;
        bottom: 12.5%;
        left: 4%;
        width: 92%;
        height: 75%;
        // border: 1px solid #000;
      }
      a {
        opacity: 0;
        position: absolute;
        text-decoration: none;
        span {
          color: #9e9da5;
          font-size: 1.25rem;
          // // font-weight: 500;
          line-height: normal;
          letter-spacing: -0.05625rem;
          transition: color 0.5s ease;
        }
        &:hover span {
          color: #000;
        }
        &.hover:not(:hover) span {
          color: rgba(158, 157, 165, 0.1);
        }
        &:nth-child(1) {
          bottom: 0.5%;
          left: 0.5%;
        }
        &:nth-child(2) {
          bottom: 34.5%;
          left: 4%;
        }
        &:nth-child(3) {
          top: 28%;
          left: 15%;
        }
        &:nth-child(4) {
          top: 8%;
          left: 32%;
        }
        &:nth-child(5) {
          top: 8%;
          right: 32%;
          white-space: wrap;
          max-width: 120px;
          text-align: right;
        }
        &:nth-child(6) {
          top: 28%;
          right: 15%;
        }
        &:nth-child(7) {
          bottom: 34.5%;
          right: 4%;
        }
        &:nth-child(8) {
          bottom: 0.5%;
          right: 0.5%;
          white-space: wrap;
          max-width: 136px;
          text-align: right;
        }
      }

      .animation-triggered a {
        opacity: 1;
        transition: opacity 0.3s ease;
        &:nth-child(1) {
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
        &:nth-child(5) {
          transition-delay: 0.5s;
        }
        &:nth-child(6) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.7s;
        }
        &:nth-child(8) {
          transition-delay: 0.8s;
        }
      }
    }
    .embla {
      position: relative;
      display: none;
      @include breakpoint-down(mobile) {
        display: block;
      }
      .circle--growth__mobile-animation {
        position: absolute;
        width: calc(100% + 48px);
        top: 3rem;
        left: -24px;
        display: flex;
        justify-content: center;
        svg {
          transform: scale(1.1);
        }
      }
      .embla__viewport {
        overflow: hidden;
        ul.embla__container {
          list-style: none;
          li.embla__slide {
            flex: 1 0 100%;
            min-width: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            h6 {
              color: #000;
              text-align: center;
              font-size: 1.25rem;
              font-weight: 500;
              line-height: 1.25rem; /* 100% */
              letter-spacing: -0.05625rem;
            }
            p {
              margin-top: 8rem;
              color: #8c8c8c;
              text-align: center;
              font-size: 0.875rem;
              // font-weight: 500;
              line-height: 160%; /* 1.4rem */
              letter-spacing: -0.035rem;
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        }
      }
      .embla__prev,
      .embla__next {
        position: absolute;
        top: 0;
        line-height: 0;
        border: 0;
        border-radius: 50%;
        svg path {
          stroke-opacity: 1;
        }
      }
      .embla__next {
        right: 0;
      }
    }
    @include breakpoint-down(mobile) {
      display: block;
      align-items: initial;
      justify-content: initial;
      overflow: initial;
      height: initial;
    }
  }
  @include breakpoint-down(mobile) {
    padding-top: 4rem;
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Our Services Section
\* ------------------------------------------------------------ */
.services--growth {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem;
  .services--growth__grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 2.25rem) [col-start]);
    column-gap: 5.5rem;
    @include breakpoint-down(mobile) {
      position: relative;
      display: flex;
      flex-direction: column;
      grid-template-columns: initial;
      column-gap: initial;
    }
  }
  .services--growth__phone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: #f3f3f3;
    z-index: 0;
    padding-block-end: 2rem;
    .services--growth__phone-wrapper {
      margin-top: 3.75rem;
      border-radius: 4rem;
      box-shadow: 0px 2.41776px 1.93421px 0px rgba(0, 0, 0, 0.02),
        0px 5.81021px 4.64817px 0px rgba(0, 0, 0, 0.03),
        0px 10.94012px 8.75209px 0px rgba(0, 0, 0, 0.04),
        0px 19.5153px 15.61224px 0px rgba(0, 0, 0, 0.04),
        0px 36.50122px 29.20098px 0px rgba(0, 0, 0, 0.05),
        0px 87.3703px 69.89624px 0px rgba(0, 0, 0, 0.07);
    }
    .phone-mockup {
      position: relative;
      z-index: 1;
      pointer-events: none;
    }
    .services--growth__phone-video {
      position: absolute;
      transform: scale(97%);
      border-radius: 2rem;
      z-index: 0;
      background-color: #202124;
      &::-webkit-media-controls {
        opacity: 0;
      }
    }
  }
  .services--growth__service-tags {
    margin-top: 3rem;
    margin-bottom: 1.75rem;
    list-style: none;
    display: flex;
    gap: 0.5rem;
    li {
      color: #070420;
      font-size: 0.875rem;
      // font-weight: 500;
      line-height: 120%; /* 1.05rem */
      letter-spacing: -0.035rem;
      border-radius: 6.1875rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 0.75rem 1.25rem;
    }
    @include breakpoint-down(mobile) {
      display: none;
    }
  }
  .services--growth__title-mobile,
  .services--growth__title-desktop {
    color: #070420;
    font-weight: 500;
    line-height: 120%; /* 2.9325rem */
  }
  .services--growth__title-mobile {
    font-size: 2.44375rem;
    letter-spacing: -0.09775rem;
    margin-bottom: 3rem;
    @include breakpoint-up(mobile) {
      display: none;
    }
  }
  .services--growth__title-desktop {
    font-size: 3.05rem;
    letter-spacing: -0.122rem;
    @include breakpoint-down(mobile) {
      display: none;
    }
  }
  .services--growth__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-color: #fff;
    @include breakpoint-down(mobile) {
      // position: relative;
      z-index: 1;
      // transform: translateY(-10rem);
      position: absolute;
      width: 100%;
    }
  }
  .embla {
    .embla__viewport {
      width: 100%;
      overflow: hidden;
      .embla__container {
        @include breakpoint-down(mobile) {
          padding-top: 36rem;
        }
        .embla__slide {
          flex: 1 0 100%;
          background-color: #fff;
        }
      }
    }
    .embla__nav {
      margin-top: 4rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 2rem;
      @include breakpoint-down(mobile) {
        margin-top: 2.25rem;
        justify-content: center;
      }
      .embla__dots-wrapper {
        display: flex;
        gap: 1rem;
        .embla__dots {
          width: 3.125rem;
          height: 0.09375rem;
          border: none;
          background: rgba(0, 0, 0, 0.15);
          .embla__dot--progress {
            width: 0;
            height: 0.09375rem;
            background-color: #000;
            transition-property: width;
            transform-style: ease;
          }
          @include breakpoint-down(mobile) {
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            .embla__dot--progress {
              display: none;
            }
            &.selected {
              background-color: #000;
            }
          }
        }
        @include breakpoint-down(large-desktop) {
          max-width: 80%;
        }
      }
      .embla__btns-wrapper {
        display: flex;
        gap: 1rem;
        @media only screen and (min-width: 1600px) {
          transform: translateX(150%);
        }
        button {
          border: none;
          background-color: transparent;
          line-height: 0;
          height: 36px;
          width: 36px;
          svg {
            transform: scale(1.5);
          }
          &:not(.disabled) {
            svg path {
              stroke-opacity: 1;
            }
          }
        }
        @include breakpoint-down(mobile) {
          display: none;
        }
      }
    }
  }
  .services--growth__carousel-content {
    padding-top: 3rem;
    div {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.25rem;
      align-items: center;
      .services--growth__carousel-content--gif {
        margin-bottom: 0;
        width: 4rem;
        height: 4rem;
        background: url(../../images/services-animated-arrow.gif) center/cover
          no-repeat;
        @include breakpoint-down(mobile) {
          width: 3rem;
          height: 3rem;
        }
      }
      h6 {
        color: #070420;
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 120%; /* 2.7rem */
        letter-spacing: -0.09rem;
        @include breakpoint-down(mobile) {
          font-size: 1.5625rem;
          letter-spacing: -0.0625rem;
        }
      }
    }
    p {
      color: #070420;
      font-size: 1rem;
      // font-weight: 500;
      line-height: 160%; /* 1.6rem */
      letter-spacing: -0.04rem;
    }
  }
  @include breakpoint-down(mobile) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Trsuted by Section
\* ------------------------------------------------------------ */
.trusted-by--growth {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .container {
    display: flex;
  }
  h5 {
    color: rgba(7, 4, 32, 0.5);
    font-size: 0.875rem;
    // font-weight: 500;
    line-height: 220%;
    text-transform: uppercase;
    padding: 1.75rem 3.5rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    width: fit-content;
    white-space: nowrap;
    @include breakpoint-down(mobile) {
      font-size: 0.75rem;
      padding: 1.25rem 1.5rem;
    }
  }
  // @include breakpoint-down(mobile) {
  //   margin-top: 16rem;
  // }
}

/* ------------------------------------------------------------ *\
	Growth Page | Case Study Section
\* ------------------------------------------------------------ */
.case-study--growth {
  padding: 6.75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @include breakpoint-down(mobile) {
    padding: 4rem 0;
  }
  .case-study--growth__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      color: #070420;
      font-size: 3.05rem;
      font-weight: 500;
      line-height: 120%; /* 3.66rem */
      letter-spacing: -0.122rem;
      @include breakpoint-down(mobile) {
        font-size: 2.44375rem;
        letter-spacing: -0.09775rem;
      }
    }
    .embla__btns-wrapper {
      display: flex;
      gap: 1rem;
      button {
        border: none;
        background-color: transparent;
        line-height: 0;
        // width: fit-content;
        height: 36px;
        width: 36px;
        svg {
          transform: scale(1.5);
        }
        &:not(.disabled) {
          svg path {
            stroke-opacity: 1;
          }
        }
      }
      @include breakpoint-down(mobile) {
        display: none;
      }
    }
  }
  .embla__viewport {
    margin: 4.5rem 0;
    @include breakpoint-down(mobile) {
      margin: 3rem 0;
    }
    > div {
      transition: transform 0.5s ease;
    }
    .embla__container {
      .embla__slide {
        flex: 0 0 auto;
        min-width: 0;
        margin-right: 2.25rem;
        @include breakpoint-down(mobile) {
          margin-right: 1rem;
        }
        .case-study--growth__img-wrapper {
          position: relative;
          width: 361px;
          height: 477px;
          background-color: #d7d7db;
          overflow: hidden;
          @include breakpoint-down(mobile) {
            width: 280px;
            height: 373px;
          }
        }
        h6 {
          color: #070420;
          font-size: 2.25rem;
          font-weight: 500;
          line-height: 120%; /* 2.7rem */
          letter-spacing: -0.09rem;
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
          @include breakpoint-down(mobile) {
            font-size: 1.5625rem;
            letter-spacing: -0.0625rem;
            margin-top: 1rem;
          }
        }
        ul {
          list-style: none;
          max-width: 361px;
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          li {
            border-radius: 6.1875rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0.75rem 1.25rem;
            color: #070420;
            font-size: 0.875rem;
            // font-weight: 500;
            line-height: 120%; /* 1.05rem */
            letter-spacing: -0.035rem;
            width: fit-content;
            @include breakpoint-down(mobile) {
              font-size: 0.75rem;
              letter-spacing: -0.03rem;
            }
          }
          @include breakpoint-down(mobile) {
            width: 280px;
          }
        }
      }
    }
  }
  .case-study--growth__cta {
    a {
      display: block;
      width: fit-content;
      color: #070420;
      font-size: 0.875rem;
      // font-weight: 500;
      line-height: 160%; /* 1.4rem */
      text-transform: uppercase;
      text-decoration: none;
      padding-bottom: 0.5rem;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transform: scale(1);
      transition: all 0.6s ease;
      position: relative;
      &::after {
        content: "";
        height: 0.0625rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.6s bounce;
      }
      &:hover {
        color: #83818f;
        transform: scale(0.95);
        &::after {
          width: 1.5rem;
          left: calc(50% - 0.75rem);
        }
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Growth Page | Ready to Work Form
\* ------------------------------------------------------------ */
.form--growth {
  padding: 6.75rem 0;
  @include breakpoint-down(mobile) {
    padding: 4rem 0;
  }
  h5,
  h6 {
    color: #070420;
    text-align: center;
    font-size: 3.8125rem;
    font-weight: 500;
    line-height: 100%; /* 3.8125rem */
    letter-spacing: -0.17156rem;
    @include breakpoint-down(mobile) {
      font-size: 2.44375rem;
      letter-spacing: -0.10994rem;
    }
  }
  h6 {
    color: #c1c0c7;
    margin-bottom: 4rem;
    @include breakpoint-down(mobile) {
      margin-bottom: 3rem;
    }
  }
  form {
    max-width: 41rem;
    margin-left: auto;
    margin-right: auto;
    input,
    textarea,
    .select-search-form .select-search__input,
    .select-search__option,
    .select-search__not-found {
      padding: 0.81rem 1rem;
      background: rgba(0, 0, 0, 0.03);
      border-color: transparent;
    }

    .select-search__select {
      border-left: none;
      border-right: none;
      // border-bottom: none;
    }
  }
  .form--growth__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    margin-bottom: 2.25rem;
    @include breakpoint-down(mobile) {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
    .form--growth__grid-column-full {
      grid-column: span 2 / span 2;
    }
    .form--growth__element {
      display: flex;
      flex-direction: column;
      label {
        color: #000;
        font-size: 0.875rem;
        // font-weight: 500;
        line-height: 0.875rem; /* 100% */
        padding-bottom: 1rem;
      }
      .form-control * {
        width: 100%;
      }
      .select-search-form.select-search {
        margin-bottom: 0;
      }
      .form-check {
        display: flex;
        align-items: flex-start;
        .form-control {
          width: fit-content;
          line-height: 0;
          margin-right: 0.5rem;
          input {
            border-radius: 0;
            width: 1rem;
            height: 1rem;
            border: 1px solid #000;
          }
        }
        label {
          display: inline-block;
          padding-bottom: 0;
          position: relative;
          &.error {
            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: -0.25rem;
              width: 100%;
              height: 0.0625rem;
              background-color: red;
            }
          }
        }
      }
    }
  }
  .btn {
    padding: 1rem 1.5rem;
  }
}

/* ------------------------------------------------------------ *\
  Infinite Loop Scroll X Animation
\* ------------------------------------------------------------ */
.marquee {
  --marquee-gap: 3.5rem;
  --marquee-duration: 60s;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--marquee-gap);
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  .marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--marquee-gap);
    min-width: 100%;
    animation: scroll-x var(--marquee-duration) linear infinite;
    img {
      display: grid;
      place-items: center;
      // margin-left: var(--marquee-gap);
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}

.trusted-by--growth {
  .marquee {
    .marquee__group {
      img {
        height: 2rem;
        width: auto;
      }
    }
  }
}

.works-ov--growth {
  .marquee {
    .marquee__group {
      img {
        height: 1.5rem;
        width: auto;
      }
    }
  }
}
