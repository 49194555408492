.useful {
  margin-top: 64px;
  text-align: center;

  @include breakpoint-down(mobile) {
    margin-top: 40px;
  }

  h6 {
    margin-bottom: 24px;
    color: #000;
  }

  .useful__cta {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    gap: 12px;
  }

  .btn {
    min-width: 100px;
    padding: 7px 15px;
    background: none;
    border-color: $color-blue-30;
    color: $color-blue-30;

    @include breakpoint-down(mobile) {
      min-width: 0;
      flex: 1;
    }

    &:hover {
      background-color: $color-blue-30;
      color: #fff;
    }
  }

  p {
    color: #000;

    a {
      color: $color-blue-30;
      font-weight: 500;
    }
  }
}
