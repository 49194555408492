.icon-cards {
  .icon-cards__grid {
    display: grid;
    @include breakpoint-down(mobile) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    &.icon-cards__grid--card {
      gap: 2.25rem;
      .icon-card {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
  }
  .embla {
    overflow: hidden;
    @include breakpoint-up(mobile) {
      display: none;
    }
  }
  .embla--slider {
    .embla__viewport {
      overflow: hidden;
    }
    .embla__container {
      display: flex;
      touch-action: pan-y pinch-zoom;
      margin: 0 24px;
    }
    .embla__slide {
      transform: translate3d(0, 0, 0);
      flex: 0 0 96%;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .icon-cards__head {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-block: 3rem;
    @include breakpoint-up(mobile) {
      max-width: 55%;
    }
    .icon-cards__title {
      font-size: 39.1px;
      font-weight: 600;
      line-height: 120%; /* 46.92px */
      letter-spacing: -1.564px;
      .icon-cards__title--colored {
        background: linear-gradient(90deg, #6456d8 0%, #a399f5 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
    }
  }
}

.icon-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding-block: 3rem;
  padding-inline: 1.5rem;
  .icon-card__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .icon-card__body-text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 1rem;
  }

  &.icon-card--left {
    text-align: left;
    .icon-card__head {
      justify-content: flex-start;
    }
  }
  &.icon-card--right {
    text-align: right;
    .icon-card__head {
      justify-content: flex-end;
    }
  }
  &.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding-block: 1.5rem;
    padding-inline: 1.5rem;
    position: relative;
    .icon-card__progress {
      display: block;
      position: absolute;
      left: 0;
      right: 100%;
      bottom: 0;
      height: 2px;
      background: linear-gradient(90deg, #2c1bb8 0%, #6759d9 100%);
      box-shadow: 0px 0px 8px 0px #352b8d;
      transition-property: right;
    }
    &.embla__slide--active .icon-card__progress {
      right: 0;
    }
  }

  .icon-card__progress {
    display: none;
  }

  .icon-card__icon {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: #2c1bb8;
  }

  .icon-card__text {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -0.8px;
  }

  &:not(:last-of-type) {
    @include breakpoint-down(mobile) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    @include breakpoint-up(mobile) {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.icon-cards--with-media {
  padding-bottom: 6rem;
  position: relative;
  .icon-cards--with-media__grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include breakpoint-up(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 6rem;
    }
    .icon-cards__grid {
      @include breakpoint-down(mobile) {
        min-height: 680px;
      }
    }
  }

  .container:not(.media--container) {
    z-index: 2;
  }
  .container.media--container {
    max-width: 1920px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  .icon-card {
    border-right: none;
    position: relative;
    max-height: calc(40px + 6rem);
    transition: all 0.25s;
    gap: 0;
    &:not(.embla__slide--active) {
      border-color: transparent !important;
      background-color: transparent !important;
      color: rgba(255, 255, 255, 0.5);
      .icon-card__icon {
        background: rgba(255, 255, 255, 0.1);
        img {
          filter: brightness(0.5);
        }
      }
    }
    .icon-card__body-text {
      border-top: none;
      margin-left: 3.6rem;
      padding-top: 0rem;
      height: 0;
      overflow: hidden;
      transition: all 0.25s;
    }
    .icon-card__progress {
      opacity: 0;
      display: block;
      position: absolute;
      left: 0;
      right: 100%;
      bottom: 0;
      height: 2px;
      background: linear-gradient(90deg, #2c1bb8 0%, #6759d9 100%);
      box-shadow: 0px 0px 8px 0px #352b8d;
      transition-property: right;
      transition-duration: 5000ms;
      transition-delay: 0.25s;
    }
    &.embla__slide--active {
      max-height: 30rem;
      padding: 36px 64px 36px 24px;
    }
    &.embla__slide--active .icon-card__progress {
      opacity: 1;
      right: 0;
    }
    &.embla__slide--active .icon-card__body-text {
      padding-top: 1rem;
      height: auto;
    }
  }

  .container:not(.media--container) .icon-cards__media {
    @include breakpoint-up(mobile) {
      display: none;
    }
  }
  .icon-cards__media {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 560px;
    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img:not(.icon-card__image--single) {
      opacity: 0;
      transition: opacity 0.5s;
    }
    @include breakpoint-down(tablet) {
      margin-bottom: 2rem;
      order: -1;
      min-height: initial;
      aspect-ratio: 1 / 1;
      img {
        aspect-ratio: 1 / 1;
        height: auto;
      }
    }
  }
}
