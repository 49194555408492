.gallery {
  padding: 98px 0 90px;
  @include breakpoint-down(mobile) {
    padding: 48px 0;
  }

  .embla__container {
    gap: 95px;

    @media (max-width: 1410px) {
      padding: 0 48px;
    }

    @include breakpoint-down(mobile) {
      gap: 24px;
      padding: 0 24px;
    }
  }

  .container {
    max-width: 1390px;

    @media (max-width: 1410px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .gallery-item {
    position: relative;
    flex: 0 0 363px;
    width: 363px;
    height: 650px;

    @include breakpoint-down(mobile) {
      flex: 0 0 190px;
      width: 190px;
      height: 341px;
    }

    img {
      border-radius: 30px;
    }
  }
}
