.promise {
  padding: 128px 0;
  margin-bottom: 156px;
  background: $color-black-07;
  color: #fff;

  @include breakpoint-down(tablet) {
    padding: 65px 0;
    margin-bottom: 35px;
  }

  @include breakpoint-down(mobile) {
    padding-bottom: 60px;
  }
}

.promise__subtitle {
  margin-bottom: 24px;
  font-size: rem(12.8);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;

  @include breakpoint-down(mobile) {
    margin-bottom: 10px;
  }
}

.promise__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 78px;
  row-gap: 64px;

  @include breakpoint-down(tablet) {
    gap: 35px;
  }

  @include breakpoint-down(mobile) {
    display: block;
  }

  p {
    font-size: rem(12.8);
    line-height: 1.8;

    @include breakpoint-down(mobile) {
      display: none;
    }
  }
}

.promise__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;

  @include breakpoint-down(mobile) {
    display: block;
    margin-bottom: 40px;
  }

  h3 {
    @include breakpoint-down(mobile) {
      margin-bottom: 16px;
    }
  }
}

.promise__content-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: rem(16);
  font-weight: 500;

  @include breakpoint-down(mobile) {
    margin-bottom: 0;
    font-size: rem(12.8);
  }

  .promise-icon {
    margin-right: 6px;

    @include breakpoint-down(mobile) {
      margin-right: 12px;
    }
  }
}

.promise__content-item {
  @include breakpoint-down(mobile) {
    padding-bottom: 20px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($color-grey-e0, 0.2);
  }

  &:last-child {
    @include breakpoint-down(mobile) {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
