.pricing-cards {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .pricing-cards__grid {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-block: 4rem;
    @include breakpoint-up(mobile) {
      flex-direction: row;
      padding-block: 6.75rem;
    }
    .pricing-cards__head {
      flex: 0 0 24%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @include breakpoint-down(mobile) {
        text-align: center;
      }
      p {
        color: #83818f;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 100%; /* 20px */
        letter-spacing: -0.8px;
        @include breakpoint-down(mobile) {
          display: none;
        }
      }
      h3 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 100%; /* 48.8px */
        letter-spacing: -1.952px;
        @include breakpoint-down(mobile) {
          text-align: center;
          font-size: 2rem;
          letter-spacing: -1.252px;
        }
      }
    }
    .pricing-cards__content {
      flex: 0 0 76%;
      display: none;
      @include breakpoint-up(mobile) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    .pricing-cards__content--mobile {
      @include breakpoint-up(mobile) {
        display: none;
      }
    }
  }
}

.price-card,
.price-card--mobile {
  .price-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        padding: 1.5rem 0.5rem;
        list-style: none;
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        p {
          color: rgba(255, 255, 255, 0.5);
        }
        h6,
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }
      }
    }
  }

  .price-card__footer {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    width: 100%;
    a {
      margin-top: 1.75rem;
      border-radius: 99px;
      border: 1px solid var(--linear-new, #2c1bb8);
      color: var(--Background-White, #fff);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      padding-inline: 1.5rem;
      background-color: transparent;
    }
  }
}

.price-card {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 4rem;
  @include breakpoint-down(mobile) {
    padding: 2rem 1rem;
  }
  border: 1px solid rgba(255, 255, 255, 0.1);
  &.price-card--active {
    background: #110e29;
  }
  .price-card__head {
    display: flex;
    h5 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 120%; /* 30px */
      letter-spacing: -1px;
      padding-bottom: 1.75rem;
    }
  }

  .price-card__body {
    ul {
      li {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.price-card--mobile {
  .price-card__head {
    display: flex;
    border-radius: 99px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #181630;
    width: 100%;
    position: relative;
    margin-bottom: 1.5rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      flex: 0 0 50%;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%; /* 14px */
      letter-spacing: -0.56px;
      background-color: transparent;
      outline: none;
      border: 1.5px solid transparent;
      z-index: 1;
    }
    .price-card__active--btn {
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 99px;
      border: 1.5px solid #2c1bb8;
      background: #181630;
      box-shadow: 0px 0px 8px 0px #352b8d;
      z-index: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }

  .price-card__body {
    ul {
      li {
        p {
          display: none;
        }
        &:not(:first-child) {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .price-card__footer {
    .btn--pricing {
      display: none !important;
    }
  }
}
