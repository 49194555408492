.scroll-text {
  .container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .scroll-text__content {
    max-width: 1100px;
    margin-inline: auto;
    p {
      text-align: center;
      font-size: 5.5rem;
      font-weight: 600;
      line-height: 100%; /* 88px */
      letter-spacing: -3.52px;
      @include breakpoint-down(mobile) {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 100%; /* 40px */
        letter-spacing: -1.6px;
      }
      .scroll-text__content--rest {
        color: rgba($color: #fff, $alpha: 0.2);
        .color-toggle {
          color: rgba($color: #fff, $alpha: 0.2);
          transition: color 0.2s;
        }
        .color-toggle.active {
          color: rgba($color: #fff, $alpha: 1);
        }
      }
    }
  }
  .scroll-text__cta {
    width: fit-content;
    margin-inline: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    @include breakpoint-down(mobile) {
      width: 100%;
      flex-direction: column-reverse;
    }

    .btn {
      @include breakpoint-down(mobile) {
        width: 100% !important;
      }
    }

    .btn--white {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0.5rem;
      margin-inline: auto;
      border: 1px solid rgba(255, 255, 255, 0.4) !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 160% !important;

      @include breakpoint-up(mobile) {
        width: fit-content !important;
      }

      @include breakpoint-down(mobile) {
        justify-content: center;
        width: 100% !important;
      }
    }
  }
}
