.image-tab {
  position: relative;
  padding: 83px 0 45px;
  overflow: hidden;

  @include breakpoint-down(tablet) {
    padding: 35px 0 20px;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 1056px;
    height: 1056px;
    border-radius: 100%;
    transform: translate(50%, -50%);
    border: 1px solid $color-grey-e0;
    max-height: 100%;

    @include breakpoint-down(tablet) {
      width: 800px;
      height: 800px;
    }

    @include breakpoint-down(mobile) {
      display: none;
    }
  }
}

.image-tab__head {
  position: relative;
  max-width: 767px;

  @include breakpoint-down(mobile) {
    margin-bottom: 24px;
  }

  h2 {
    margin-bottom: 40px;
    line-height: 1.399959;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 0;
    }
  }
}

.image-tab__row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;

  @include breakpoint-down(mobile) {
    display: block;
    margin-bottom: 25px;
  }

  button {
    padding: 7px 8px;
    border: 0;
    cursor: pointer;
    background: none;
    font-size: rem(12.8);
    line-height: 1.6;
    font-weight: 500;
    border-bottom: 2px solid $color-light-f0;

    &.is-active {
      color: $color-blue-30;
      border-color: $color-blue-30;
    }
  }
}

.image-tab__row-image {
  position: relative;
  text-align: right;
  padding-top: 10px;

  .slide {
    .slide-image {
      padding: 0 0 8% 29%;
      @include breakpoint-down(mobile) {
        text-align: center;
        padding: 0;
      }
    }
  }
}

.slide-image__desktop,
.slide-image__mobile .slide-image__mobile-inner {
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.slide-image__desktop {
  font-size: 0;

  @include breakpoint-down(mobile) {
    display: none;
  }
}

.slide-image__mobile {
  position: absolute;
  left: 9%;
  bottom: 0;
  max-width: 22.2%;
  font-size: 0;

  @include breakpoint-down(mobile) {
    display: flex;
    position: static;
    max-width: 100%;
    justify-content: center;
  }
}

.image-tab__row-left {
  @include breakpoint-down(mobile) {
    margin-bottom: 35px;
  }
}

.image-tab__row-right {
  @include breakpoint-down(mobile) {
    margin-bottom: 25px;
  }
}
