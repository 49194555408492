.article {
  position: relative;
  padding-top: 68px;

  @include breakpoint-down(mobile) {
    padding-top: 52px;
  }

  .article__inner {
    display: flex;
    justify-content: center;
    min-height: 142px;
  }

  .article__content {
    width: 100%;
    max-width: 880px;
    font-size: rem(18);
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }

    &,
    p {
      line-height: 1.79;

      @include breakpoint-down(mobile) {
        line-height: 1.64;
      }
    }

    @include breakpoint-down(mobile) {
      p {
        letter-spacing: -0.6px;
      }
    }

    > *:not(:last-child) {
      margin-bottom: 25px;

      @include breakpoint-down(mobile) {
        margin-bottom: 24px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:not(:first-child) {
        margin-top: 63px;

        @include breakpoint-down(mobile) {
          margin-top: 48px;
        }
      }
    }

    ul,
    ol {
      list-style-position: inside;
    }

    blockquote {
      padding-left: 10px;
      border-left: 2px solid $color-blue-30;
    }
  }
}

.article__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  font-size: rem(12.8);
  line-height: 1.8;
  color: rgba(7, 4, 32, 0.5);

  .article__meta-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .article__meta-tags span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .article__meta-tags span + span:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 1px;
    background: rgba(7, 4, 32, 0.5);
  }
}

.article__meta--bright {
  color: rgba(255, 255, 255, 0.5);

  .article__meta-tags {
    color: $color-white;
  }

  .article__meta-tags span + span:before {
    width: 35px;
    background: $color-white;

    @include breakpoint-down(mobile) {
      width: 20px;
    }
  }
}
