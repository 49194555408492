.theme-listing {
  padding: 96px 0;

  @include breakpoint-down(tablet) {
    padding: 75px 0 35px;
  }

  @include breakpoint-down(mobile) {
    padding: 70px 0 0;
  }

  a {
    text-decoration: none;
  }
}

.theme-listing__inner {
  display: grid;
  gap: 65px;
  grid-template-columns: 1fr 1fr;

  @include breakpoint-down(mobile) {
    display: block;
  }
}

.theme-listing__item {
  margin-bottom: 65px;
  @include breakpoint-down(mobile) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.theme-listing__slider {
  .slider-holder .slick-slider {
    padding-left: 0px;
  }
  .slider-holder .slick-slide {
    padding: 0px;
  }
}

.theme-listing__image {
  display: block;
  position: relative;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  margin-bottom: 28px;
  font-size: 0;

  &:hover {
    .theme-listing__image-overlay {
      transform: translateY(0);

      span {
        transform: translateY(0);
        transition: transform 0.3s 0.3s;
      }
    }
  }
}

.theme-listing__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 64px;
  background-color: rgba($color-black-07, 0.8);
  transition: transform 0.5s;
  transform: translateY(-100%);
  overflow: hidden;
  color: #fff;

  span {
    display: inline-flex;
    align-items: center;
    transform: translateY(100px);
    font-size: 16px;
    font-weight: 500;
  }
}

.theme-listing__variants {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;

  .theme-listing__variant {
    width: 18px;
    height: 18px;
    border-radius: 50%;

    @include breakpoint-down(mobile) {
      width: 14px;
      height: 14px;
    }
  }
}

.theme-listing__head {
  display: flex;
  justify-content: space-between;
  font-size: rem(16);
  color: $color-black-07;

  @include breakpoint-down(mobile) {
    font-size: rem(12.8);
  }

  .theme-listing__head-title {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    span {
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 0.25rem;
      color: $color-base-5e;
      @include breakpoint-down(mobile) {
        font-size: 0.75rem;
      }
    }
  }
}
