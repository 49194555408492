.template-lead-landing {
  .main {
    @include breakpoint-down(mobile) {
      padding-bottom: 0;
    }
  }

  .dynamic-section {
    position: relative;
  }

  .intro {
    margin-bottom: 4rem;
    @include breakpoint-down(mobile) {
      margin-bottom: 0;
    }
    .intro__image {
      @include breakpoint-down(mobile) {
        display: none;
      }
    }
  }

  .form--builder {
    background-color: #f0f1fa;
    @include breakpoint-up(mobile) {
      position: absolute;
      left: calc(50% + 34px);
      top: 54px;
      z-index: 3;
      max-width: 620px;
      @include breakpoint-down(large-desktop) {
        max-width: calc(50% - 34px - 56px);
      }
      @include breakpoint-down(desktop) {
        max-width: calc(50% - 17px);
      }
    }
    form {
      margin: initial;
      input,
      textarea,
      .select-search-form .select-search__input,
      .select-search__option,
      .select-search__not-found {
        background: #fff;
      }
    }
  }

  .text-block {
    padding-top: 3rem;
    p {
      color: var(--Typography-Light, #5e5c70);
      font-size: 1rem;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      @include breakpoint-down(mobile) {
        font-size: 0.8rem;
        line-height: 1;
      }
    }
    h5 {
      color: var(--Typography-Primary, #000);
      font-size: 1rem;
      font-weight: 500;
      line-height: 160%; /* 25.6px */
      @include breakpoint-down(mobile) {
        font-size: 0.8rem;
        line-height: 1;
      }
    }
    .container--small {
      max-width: 1440px;
    }
    .text-block__inner {
      @include breakpoint-up(mobile) {
        max-width: calc(50% - 34px);
      }
      margin: 0;
    }
  }

  .lead-landing--icon-list {
    li {
      position: relative;
      list-style-type: none;
    }

    li:before {
      content: "";
      position: absolute;
      left: -2rem;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("../../images/Tick.svg");
      @include breakpoint-down(mobile) {
        top: -0.333rem;
      }
    }
  }

  .logos {
    @include breakpoint-down(mobile) {
      text-align: start;
      h2 {
        max-width: 20rem;
        font-size: 1.5rem;
        line-height: 140%; /* 28px */
      }

      .logos__items .logo img {
        max-width: 6rem;
      }
    }
  }
}
