.categories-listing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 64px;

  @include breakpoint-down(tablet) {
    grid-template-columns: 1fr;
  }

  @include breakpoint-down(mobile) {
    gap: 45px;
    margin-bottom: 20px;
  }

  h6 {
    margin-bottom: 24px;
    color: #000;

    @include breakpoint-down(mobile) {
      margin-bottom: 18px;
    }
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 18px;
  }

  a {
    color: $color-blue-30;
    text-decoration: none;
    font-size: rem(12.8);
  }

  .categories-listing__all {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
