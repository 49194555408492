.faq-items-holder {
  margin-top: 40px;
  color: #000;
}

.faq-items-box {
  margin-top: 40px;

  h4 {
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 15px;
  }

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: #000;
      transition: width 0.3s;
    }

    @media (hover: hover) {
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
}
