.template-development {
  overflow: initial;
  .slider-element {
    overflow: hidden;
  }
  .main {
    .btn--fill {
      border-radius: 99px;
      border: none;
      outline: 1px solid rgba(255, 255, 255, 0.25);
      background: linear-gradient(90deg, #2c1bb8 0%, #6759d9 100%);
      &:hover {
        border: none;
        outline: 1px solid rgba(255, 255, 255, 1);
      }
    }
    .btn--white {
      border-radius: 99px;
      border: 1px solid var(--linear-new, #2c1bb8);
    }
    .btn {
      color: var(--Background-White, #fff);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      padding-inline: 1.5rem;
      min-width: initial;
      width: initial;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: transparent;
        background: linear-gradient(90deg, #181630 0%, #181630 100%);
        color: var(--Background-White, #fff);
        border-color: var(--Background-White, #fff);
      }
    }
    form {
      h5,
      h6 {
        color: #fff;
        text-align: center;
        font-size: 61px;
        font-weight: 600;
        line-height: 100%; /* 61px */
        letter-spacing: -2.745px;
        @include breakpoint-down(mobile) {
          font-size: 39.1px;
          font-weight: 600;
          line-height: 100%; /* 39.1px */
          letter-spacing: -1.759px;
          margin-bottom: 3rem;
        }
      }
      h6 {
        color: #c1c0c7;
      }
      input,
      textarea,
      .select-search-form .select-search__input,
      .select-search__option,
      .select-search__not-found {
        background: rgba(255, 255, 255, 0.03);
        border-color: transparent;
        @include breakpoint-down(mobile) {
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: rgba(255, 255, 255, 0.05);
        }
      }
      button {
        width: 100% !important;
      }
    }
    .form--builder__element:not(:nth-child(7)):not(:last-child) {
      grid-column: initial !important;
    }
    .form--builder .form--builder__grid .form--builder__element label {
      color: #fff;
    }
  }
  .intro {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0;
    @include breakpoint-up(mobile) {
      border-block: 1px solid rgba(255, 255, 255, 0.1);
    }
    padding: 0;
    .intro__content {
      h3,
      .h3 {
        text-align: center;
        font-size: 2.5rem;
        line-height: 100%; /* 39.1px */
        letter-spacing: -1.564px;
        @include breakpoint-up(mobile) {
          text-align: left;
          font-size: 3.8125rem;
          font-weight: 600;
          line-height: 100%; /* 61px */
          letter-spacing: 0.1525rem;
        }
      }
      p {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
        @include breakpoint-down(mobile) {
          text-align: center;
        }
      }
      .cta-row {
        gap: 0.5rem;
        @include breakpoint-down(mobile) {
          justify-content: center;
        }
      }
    }
    .intro__image {
      margin-bottom: 0;
      @include breakpoint-up(mobile) {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        padding: 56px;
      }
      .image-1 {
        position: relative;
      }
      .image-1__inner::before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      video {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        pointer-events: none;
        mix-blend-mode: lighten;
      }
    }
    .intro__inner {
      gap: 0;
      @include breakpoint-down(mobile) {
        gap: 2.5rem;
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .intro--fifty-grid {
      --grid-col-width: 50%;
    }
  }

  .trusted-by--growth {
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-block: 1.5rem;
    background-color: rgba(255, 255, 255, 0.03);

    h5 {
      color: inherit;
      background-color: transparent;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      display: none;
    }
    .marquee .marquee__group img {
      height: 1.5rem;
      filter: brightness(2);
    }
  }

  .scroll-text {
    background-image: url("../../images/scroll-text-bg-desktop.jpg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    @include breakpoint-down(mobile) {
      background-image: url("../../images/scroll-text-bg-mobile.jpg");
    }
  }

  .official-partner__badge {
    display: flex;
    padding: 12px var(--border-radius, 16px);
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--linear-new, #2c1bb8);
    width: fit-content;
    span {
      font-size: 10px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  .intro {
    .official-partner__badge {
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      margin-bottom: 2.25rem;
      @include breakpoint-down(mobile) {
        margin-inline: auto;
      }
    }
  }
}
