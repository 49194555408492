.teal-box {
  padding: 96px 0;
  background-color: $color-light-f0;

  @include breakpoint-down(tablet) {
    padding: 75px 0;
  }

  @include breakpoint-down(mobile) {
    padding: 65px 0;
  }

  .h3 {
    margin-bottom: 16px;
    color: $color-black-07;
  }

  p {
    font-size: rem(20);
    color: $color-base-5e;
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
      line-height: 1.8;
    }
  }

  &--center {
    .teal-box__inner {
      margin: 0 auto;
    }
  }
}
