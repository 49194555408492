.quick-help {
  .quick-help__head {
    padding: 7px 0;
    background: $color-blue-30;
    font-size: rem(12.8);
    text-align: center;
    color: #fff;

    @include breakpoint-down(mobile) {
      padding: 4px 0;
      font-size: rem(10.2);
    }
  }

  a {
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 0 5px $color-grey-e0;
    }
  }

  .quick-help__box {
    display: block;
    margin-bottom: 30px;
    padding: 24px 12px;
    border: 1px solid $color-grey-e0;
    text-align: center;
    text-decoration: none;

    @include breakpoint-down(mobile) {
      display: grid;
      column-gap: 12px;
      row-gap: 4px;
      grid-template-columns: 32px 1fr;
      grid-template-rows: 20px 20px;
      margin-bottom: 14px;
      padding: 14px;
      text-align-last: left;
    }
  }

  .quick-help__icon {
    margin-bottom: 10px;
    align-items: center;

    @include breakpoint-down(mobile) {
      grid-row: 1 / 3;
      margin: 0;
    }

    svg {
      @include breakpoint-down(mobile) {
        max-width: 32px;
        height: auto;
      }
    }
  }

  .quick-help__title {
    margin-bottom: 8px;
    font-size: rem(16);
    font-weight: 500;
    color: #000;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
      text-align: left;
    }
  }

  .quick-help__content {
    font-size: rem(12.8);
    @include breakpoint-down(mobile) {
      margin: 0;
      line-height: 1;
      font-size: rem(10.2);
      text-align: left;
    }
  }
}

.quick-help--horizontal {
  @include breakpoint-down(tablet) {
    margin-top: 30px;
  }

  @include breakpoint-down(mobile) {
    margin-top: 45px;
  }

  .quick-help__head {
    background: none;
    margin-bottom: 17px;
    font-size: rem(20);
    font-weight: 700;
    color: #000;
    text-align: left;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
    }
  }

  .quick-help__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  .quick-help__box {
    flex: 1;
  }
}
