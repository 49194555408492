.tabs {
  padding: 80px 0 68px;

  @include breakpoint-down(mobile) {
    padding: 37px 0 14px;
  }

  .tabs__inner {
    display: flex;
    justify-content: space-between;
    gap: 22.8%;

    @include breakpoint-down(tablet) {
      gap: 5%;
    }
  }
}

.tabs__side {
  flex: 1 1 auto;

  @include breakpoint-down(mobile) {
    display: none;
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 60px;
  }

  .tab-button {
    display: flex;
    align-items: center;
    opacity: 0.4;
    font-size: rem(48.83);
    font-weight: 700;
    background: none;
    border: 0;
    transition: opacity 0.3s ease-in-out;

    .tab-button__icon {
      margin-left: 40px;
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }

    &.is-active {
      opacity: 1;

      .tab-button__icon {
        transform: rotate(0);
      }
    }
  }
}

.tabs__content {
  font-size: rem(16);

  .tab-content {
    .tab-content__inner {
      @include breakpoint-down(mobile) {
        padding-bottom: 10px;
        margin-top: 29px;
      }
    }
  }

  .tabs__content-title {
    position: relative;
    z-index: 1;
    display: none;

    @include breakpoint-down(mobile) {
      display: block;
      margin-bottom: 0;
    }

    .tab-button {
      display: flex;
      width: 100%;
      background: none;
      border: 0;
      border-radius: 0;
      appearance: none;
      font-size: rem(25);
      font-weight: 700;
      text-align: left;
      justify-content: space-between;

      .tab-button__icon {
        transition: transform 0.3s;
        transform: rotate(-90deg);
      }

      &.is-active {
        .tab-button__icon {
          transform: rotate(45deg);
        }
      }
    }
  }

  p {
    margin-bottom: 40px;
    font-size: rem(20);
    line-height: 1.8;

    @include breakpoint-down(mobile) {
      margin-bottom: 24px;
      font-size: rem(16);
      line-height: 1.5;
    }
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 10px;
    list-style: none;
    font-size: rem(16);

    @include breakpoint-down(mobile) {
      grid-template-columns: 1fr;
      font-size: rem(12.8);
    }

    li {
      margin-bottom: 34px;

      @include breakpoint-down(mobile) {
        margin-bottom: 24px;
      }
    }
  }
}
