.size {
  padding: 96px 0;
  background-color: $color-light-f0;
  text-align: center;

  @include breakpoint-down(mobile) {
    padding: 66px 0 36px;
    text-align: left;
  }

  h3 {
    margin-bottom: 16px;
    color: #000;
  }

  p {
    font-size: 20px;
    line-height: 1.5;

    @include breakpoint-down(mobile) {
      font-size: rem(16);
      line-height: 1.6;
    }
  }

  .size__content {
    max-width: 770px;
    margin: 0 auto 66px;

    @include breakpoint-down(mobile) {
      margin-bottom: 40px;
    }
  }

  .size__row {
    display: flex;
    gap: 40px;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  .size__box {
    margin-bottom: 24px;
    p {
      font-size: rem(12.8);
      line-height: 1.859375;

      @include breakpoint-down(mobile) {
        font-size: rem(10.2);
        line-height: 1.8;
      }
    }
  }

  .size__box-title {
    margin-bottom: 5px;
    font-size: rem(16);
    color: #000;
    font-weight: 500;

    @include breakpoint-down(mobile) {
      font-size: rem(12.8);
    }
  }
}
