/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear {
  @util clearfix;
}

/*  Notext  */

.notext {
  @util text-hide;
}

/*  Hidden  */

[hidden],
.hidden {
  display: none !important;
}

/*  Alignleft  */

.alignleft {
  float: left;
}

/*  Alignright  */

.alignright {
  float: right;
}

/*  Disabled  */

[disabled],
.disabled {
  cursor: default;
}

/*  Grid  */

.col {
  max-width: 100%;
  flex: 1;

  &s {
    display: flex;
    flex-flow: row wrap;
  }

  &--1of2 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: $responsive-xs) {
  .hidden-xs {
    display: none !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $responsive-xs + 1) and (max-width: $responsive-sm) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $responsive-sm + 1) and (max-width: $responsive-md) {
  .hidden-md {
    display: none !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $responsive-md + 1) {
  .hidden-lg {
    display: none !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

.ta-c {
  text-align: center;
}

.mobile-only {
  display: none !important;

  @include breakpoint-down(mobile) {
    display: inline-block !important;
  }
}

.overflow-hidden {
  overflow: hidden;
  height: 100px;
}
