.copy-row {
  display: flex;
  justify-content: space-between;
  padding-top: 31px;
  margin-top: 30px;
  border-top: 1px solid rgba($color-grey-e0, 0.2);

  @include breakpoint-down(mobile) {
    display: block;
    margin-top: 0;
  }

  p {
    font-size: rem(12.8);

    @include breakpoint-down(mobile) {
      margin-bottom: 16px;
    }
  }

  ul {
    display: flex;
    list-style: none;

    @include breakpoint-down(mobile) {
      justify-content: space-between;
    }

    li {
      margin-left: 25px;

      @include breakpoint-down(mobile) {
        margin: 0;
      }
    }

    a {
      display: inline-block;
      position: relative;
      font-size: rem(12.8);
      text-decoration: none;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        transform: translateX(-50%);
        background-color: #fff;
        transition: width 0.3s;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
}
