.intro {
  padding: 55px 0 46px;
  margin-bottom: 70px;
  background-color: $color-black-07;
  color: #fff;

  @include breakpoint-down(tablet) {
    padding: 40px 0;
    margin-bottom: 35px;
  }

  @include breakpoint-down(mobile) {
    margin-bottom: 93px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.intro--blog {
    padding-bottom: 64px;
    margin-bottom: 64px;

    @include breakpoint-down(mobile) {
      padding-bottom: 47px;
      margin-bottom: 48px;
    }
  }

  &.intro--article {
    padding: 27px 0 55px;

    @include breakpoint-down(mobile) {
      padding-top: 42px;
    }
  }
}

.intro__inner {
  display: flex;
  justify-content: space-between;
  gap: 68px;
  align-items: center;

  @include breakpoint-down(tablet) {
    display: block;
  }

  &.no-images {
    .intro__content {
      flex: 0 0 90%;
      max-width: 90%;
    }
  }

  &.intro--blog {
    max-width: 769px;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 36px;
    gap: 31px;

    @include breakpoint-down(mobile) {
      padding-top: 2px;
    }
  }

  &.intro--article {
    align-items: flex-end;

    h3 {
      margin-bottom: 31px;

      @include breakpoint-down(mobile) {
        margin-bottom: 16px;
      }
    }

    .intro__image {
      padding-bottom: 3px;

      @include breakpoint-down(tablet) {
        margin-top: 30px;
        text-align: center;
      }

      @include breakpoint-down(mobile) {
        width: 100vw;
        margin: 48px 0 -62px -24px;
      }
    }
  }
}

@include breakpoint-up(mobile) {
  .intro--fifty-grid {
    --grid-col-width: calc(50% - 34px);
    .intro__content {
      flex: 0 0 50%;
      max-width: var(--grid-col-width);
    }

    .intro__image {
      flex: 0 0 50%;
      max-width: var(--grid-col-width);
    }
  }
}

.intro__content {
  flex: 0 0 41%;
  max-width: 41%;

  @include breakpoint-down(tablet) {
    max-width: 100%;
  }

  h1,
  .h1 {
    margin-bottom: 32px;
    line-height: 1.393443;

    @include breakpoint-down(mobile) {
      line-height: 1.179487;
    }
  }

  h3,
  .h3 {
    margin-bottom: 16px;
    line-height: 1.400154;
  }

  p {
    margin-bottom: 30px;
    line-height: 1.6;
  }

  .intro--article & {
    flex: 0 0 auto;
    max-width: auto;
  }
}

.intro__image {
  flex: 0 0 53%;

  @include breakpoint-down(mobile) {
    margin-bottom: -120px;
  }

  &.two-images {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 65px;

    @include breakpoint-down(tablet) {
      justify-content: flex-start;
    }

    @include breakpoint-down(mobile) {
      margin-bottom: -80px;
    }

    .image-1 {
      position: relative;
      padding-left: 18%;
    }

    .image-1__inner {
      img {
        border-radius: 20px 0;
      }
    }

    .image-2 {
      position: absolute;
      top: 25.6%;
      left: 0;
      max-width: 36.3%;
      border-radius: 5px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

      .image-2__inner {
        border: 5px solid $color-grey-e0;
        font-size: 0;
        border-radius: 5px;
      }
    }
  }

  .intro--article & {
    flex: 0 0 50%;
  }
}

.dynamic-section {
  & > *:last-child:not(.slider-element) {
    @include breakpoint-down(mobile) {
      padding-bottom: 0;
    }
  }

  .intro {
    padding: 54px 0 123px;

    @include breakpoint-down(tablet) {
      padding: 54px 0;
    }

    @include breakpoint-down(mobile) {
      margin-bottom: 50%;
    }

    &.intro--smaller {
      padding: 157px 0 120px;
    }

    &.no-images {
      @include breakpoint-down(tablet) {
        padding: 64px 0;
        margin: 0;
      }

      @include breakpoint-down(mobile) {
        padding: 64px 0;
      }

      .intro__content {
        max-width: 100%;
        margin: 0;
      }
    }
  }

  .intro__image {
    @include breakpoint-down(mobile) {
      margin-bottom: -60%;
    }
  }

  .intro__content {
    @include breakpoint-down(tablet) {
      margin-bottom: 48px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .cta-row {
    gap: 26px;
    .btn {
      width: 180px;
    }
  }
}

.intro__head {
  display: flex;
  margin-bottom: 25px;
  font-size: rem(12.8);
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.5);

  &-date {
    display: flex;
    align-items: center;

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      margin: 0 8px;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @include breakpoint-down(mobile) {
    margin-bottom: 16px;
  }
}

.intro__foot {
  display: flex;
  justify-content: space-between;
}
