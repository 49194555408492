.stack {
  margin: 0 0 166px;

  @include breakpoint-down(tablet) {
    margin-bottom: 85px;
  }

  @include breakpoint-down(mobile) {
    padding: 28px 0 0;
  }
}

.stack__head {
  max-width: 656px;
  margin-bottom: 64px;

  @include breakpoint-down(mobile) {
    margin-bottom: 32px;
  }

  h2 {
    margin-bottom: 30px;
    color: $color-black-07;

    @include breakpoint-down(mobile) {
      margin-bottom: 16px;
      font-size: rem(25);
    }

    strong {
      color: $color-blue-30;
    }
  }

  p {
    margin: 0;
    line-height: 1.6;

    @include breakpoint-down(mobile) {
      line-height: 1.8;
    }
  }
}

.stack__logos {
  display: flex;
  flex-wrap: wrap;
  padding: 64px 0 0;
  border-top: 1px solid $color-grey-e0;
  row-gap: 24px;
  column-gap: 94px;

  @include breakpoint-down(mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px 0 0;
    gap: 26px;
  }
}
