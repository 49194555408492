/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
  font-size: $font-size-base;
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
  background: #fff;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-base-5e;
  font-display: swap;
  scroll-behavior: smooth;

  @include breakpoint-down(mobile) {
    font-size: rem(12.8);
  }
}

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-SemiBold.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: underline;

  &:hover,
  &[href^="tel"] {
    text-decoration: none;
  }
}

.sr-only {
  visibility: hidden;
  display: inline-block;
  width: 0;
  height: 0;
}
