/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-family-sans-serif: "Poppins", Arial, Helvetica, sans-serif;
$font-family-serif: Times, "Times New Roman", serif;

$font-size-base: 16px;
$line-height-base: 1.3;

$shell-max-width: 1200px;
$shell-gutter: 10px;

$responsive-xxs: 374px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;

$mobile-small: "(max-width: #{$responsive-xxs}) ";
$mobile: "(max-width: #{$responsive-xs}) ";
$tablet-portrait: "(max-width: #{$responsive-sm}) ";
$small-desktop: "(max-width: #{$responsive-md}) ";
$retina: "(min-resolution: 2dppx) ";

$color-base-5e: #5e5c70;
$color-black-33: #333333;
$color-black-07: #070420;
$color-blue-30: #301dcd;
$color-light-f0: #f0f1fa;
$color-grey-e0: #e0dfe5;
$color-white: #ffffff;

$color-typography-primary: #000000;

$responsive-ios: 812px;

$background-light: #f0f1fa;

// Usage
// @media #{$small-desktop}

$breakpoints: (
  mobile-small: 374px,
  mobile-medium: 575px,
  mobile: 767px,
  tablet: 1023px,
  small-desktop: 1199px,
  large-desktop: 1399px,
);
