.progress-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 1;
}

.progress-bar {
  --scroll-factor: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: $color-blue-30;
  transform-origin: left top;
  transform: scaleX(var(--scroll-factor));
  transition: transform .1s linear;

  &.fixed {
    position: fixed;

    @include breakpoint-down(mobile) {
      top: 56px;
    }
  }
}
