.form--builder {
  padding: 4rem 0;
  @include breakpoint-down(mobile) {
    padding: 1.5rem 0;
  }
  h5 {
    color: #070420;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @include breakpoint-down(mobile) {
      font-size: 1.25rem;
      letter-spacing: -0.10994rem;
      margin-bottom: 1rem;
    }
  }
  .form--builder__subtitle {
    color: #c1c0c7;
    margin-bottom: 2.25rem;
    text-align: center;
    font-size: 61px;
    font-weight: 600;
    line-height: 100%; /* 61px */
    letter-spacing: -2.745px;
    @include breakpoint-down(mobile) {
      font-size: 20px;
      letter-spacing: -0.8px;
      margin-bottom: 1rem;
    }
  }
  form {
    max-width: 41rem;
    margin: 0 auto;
    input,
    textarea,
    .select-search-form .select-search__input,
    .select-search__option,
    .select-search__not-found {
      padding: 0.81rem 1rem;
      background: rgba(0, 0, 0, 0.03);
      border-color: transparent;
      @include breakpoint-down(mobile) {
        font-size: 0.8rem;
        line-height: 1;
      }
    }

    .select-search__select {
      border-left: none;
      border-right: none;
      // border-bottom: none;
    }
  }
  .form--builder__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    margin-bottom: 2.25rem;
    @include breakpoint-down(mobile) {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
    .form--builder__grid-column-full {
      grid-column: span 2 / span 2;
    }
    .form--builder__element {
      display: flex;
      flex-direction: column;
      label {
        color: #000;
        font-size: 0.875rem;
        // font-weight: 500;
        line-height: 0.875rem; /* 100% */
        padding-bottom: 1rem;
      }
      .form-control * {
        width: 100%;
      }
      .select-search-form.select-search {
        margin-bottom: 0;
      }
      .form-check {
        display: flex;
        align-items: flex-start;
        .form-control {
          width: fit-content;
          line-height: 0;
          margin-right: 0.5rem;
          input {
            border-radius: 0;
            width: 1rem;
            height: 1rem;
            border: 1px solid #000;
          }
        }
        label {
          display: inline-block;
          padding-bottom: 0;
          position: relative;
          &.error {
            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: -0.25rem;
              width: 100%;
              height: 0.0625rem;
              background-color: red;
            }
          }
        }
      }
    }
  }
  .btn {
    padding: 1rem 1.5rem;
  }
}
